<template>
  <b-container>
    <b-row v-if="this.$route.name === `holidayEmployee`">
      <template>
        <div class="col-12">
          <b-card
            title="Solicitud de Vacación"
            class="card card-custom card-stretch gutter-b"
          >
            <b-row>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Nombre: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <label label-cols-sm="7">{{ name }}</label>
                </b-form-group>
                <b-form-group
                  label="Dias disponible de vacación: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  label-class="font-weight-bold text-primary"
                  class="mb-0"
                >
                  <label label-cols-sm="7">{{ available_days }}</label>
                </b-form-group>

                <b-form-group
                  label="Fechas Solicitadas: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <label label-cols-sm="7"
                    >{{
                      range_request.start
                        ? moment(range_request.start).format("YYYY-MM-DD ")
                        : ""
                    }}/{{
                      range_request.end
                        ? moment(range_request.end).format("YYYY-MM-DD")
                        : ""
                    }}- ({{ RequestDays }})
                  </label>
                </b-form-group>
              </b-col>

              <b-col
                v-if="action == 'edit'"
                lg="6"
                md="4"
                class="col align-self-end"
              >
                <b-form-group>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="float-right ml-3"
                    ref="buttonDelete"
                    @click="deleteRequest"
                  >
                    <b-icon icon="trash-fill" font-scale="1"></b-icon>
                    Eliminar solicitud
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <br />
        </div>
      </template>

      <template>
        <div class="container-fluid">
          <form class="bg-primary shadow-md rounded px-8 pt-6 pb-8">
            <div class="mb-4">
              <DatePicker
                color="blue"
                fillMode="solid"
                is-expanded
                :attributes="attrs"
                v-model="range_request"
                is-range
              >
              </DatePicker>
              <b-button
                variant="success"
                size="sm"
                class="float-right ml-5 mt-1"
                ref="buttonRequest"
                @click="saveUpdateVacation"
              >
                {{ action === "edit" ? "Actualizar" : "Solicitar" }}
              </b-button>
            </div>
          </form>
          <br />
        </div>
      </template>
    </b-row>
  </b-container>
</template>

<script>
var _ = require("lodash");
import { mapGetters } from "vuex";
var moment = require("moment");
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import {
  STORE_VACATION,
  UPDATE_VACATION,
  SHOW_USER_VACATION,
  DELETE_VACATION
} from "@/services/store/holiday.module";

import Swal from "sweetalert2";
export default {
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters([
      "getEmployeeProfile",
      "currentUser",
      "getEmployeesAll",
      "getVacation"
    ]),
    isPendiente() {
      if (this.getVacation) {
        return this.getVacation.relationships.status.attributes.name;
      } else {
        return "";
      }
    },
    employees_all() {
      return _.map(this.getEmployeesAll, e => {
        return { id: e.id, name: e.attributes.name };
      });
    },
    name() {
      return this.getEmployeeProfile.attributes.name;
    },
    id() {
      return this.getVacation.attributes.id;
    },
    available_days() {
      return this.getEmployeeProfile.attributes.available_days_vacations;
    },
    RequestDays() {
      if (this.range_request.start != "" && this.range_request.end != "") {
        var from = moment(this.range_request.start, "YYYY-MM-DD"),
          to = moment(this.range_request.end, "YYYY-MM-DD"),
          holiday = this.prueba12,
          days = 0;
        while (!from.isAfter(to)) {
          // Si no es sabado ni domingo
          var aux = _.find(holiday, function(e) {
            return e == moment(from).format("YYYY, M, DD");
          });
          if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
            days++;
          }
          from.add(1, "days");
        }
        return days;
      } else {
        return "";
      }
    }
  },

  data() {
    return {
      prueba12: this.$route.params.prueba,
      attrs: [
        {
          description: "Take Noah to basketball practice.",
          key: "today",
          highlight: {
            color: "purple",
            fillMode: "solid",
            contentClass: "italic"
          },
          dates: this.$route.params.prueba,

          popover: {
            label: "Feriado "
          }
        }
      ],
      action: this.$route.params.action,
      form: {
        id: "",
        profile_id: "",
        date_init: "",
        date_end: "",
        prueba1: "",
        prueba2: "",
        created_by: ""
      },
      vacation: this.$route.params.request,
      range_request: {
        start: "",
        end: ""
      },
      dayVacation: {
        day: ""
      },
      range_aproved: {
        start: "",
        end: ""
      },
      masks: {
        input: "YYYY-MM-DD"
      },
      total_day: 0,

      //id: this.$route.params.vacation
      notification: false,
      res: {}
      //socket: io("localhost:3001")
    };
  },
  created() {
    this.range_request.start =
      this.action === "edit" ? this.vacation.attributes.date_init : "";
    this.range_request.end =
      this.action === "edit" ? this.vacation.attributes.date_end : "";
  },
  beforeMount() {
    //this.$store.dispatch(LIST_HOLIDAYS);
  },
  mounted() {},
  methods: {
    availableDays() {
      return this.getEmployeeProfile.attributes.available_days_vacations;
    },
    DaysRequest() {
      // var holiday = moment("18/01/2021", "YYYY-MM-DD");
      if (this.range_request.start != "" && this.range_request.end != "") {
        var from = moment(this.range_request.start, "YYYY-MM-DD"),
          to = moment(this.range_request.end, "YYYY-MM-DD"),
          holiday = this.prueba12,
          days = 0;
        while (!from.isAfter(to)) {
          // Si no es sabado ni domingo

          var aux = _.find(holiday, function(e) {
            return e == moment(from).format("YYYY, M, DD");
          });
          if (
            from.isoWeekday() !== 6 &&
            from.isoWeekday() !== 7 &&
            !aux
            //!holiday.includes(from.format("YYYY, M, DD"))
          ) {
            days++;
          }
          from.add(1, "days");
        }
        return days;
      } else {
        return "";
      }
    },

    succesRequest(data) {
      // e.preventDefault();
      this.$socket.emit("SUCCESS_REQUEST", data);
      // this.message = "";
    },
    saveUpdateVacation() {
      if (this.action == "edit") {
        this.updateVacation();
      } else {
        this.saveVacation();
      }
    },
    saveVacation() {
      const submitButton = this.$refs["buttonRequest"];

      this.form.date_init = this.moment(this.range_request.start).format(
        "YYYY-MM-DD hh:mm:ss"
      );
      this.form.date_end = this.moment(this.range_request.end).format(
        "YYYY-MM-DD hh:mm:ss"
      );
      this.form.prueba1 = this.DaysRequest();
      this.form.prueba2 = this.availableDays();
      if (this.form.prueba1 > this.form.prueba2) {
        Swal.fire(
          "Error!",
          "Excediste el limite de días disponibles de vacación",
          "error"
        );

        return;
      }

      this.$store
        .dispatch(STORE_VACATION, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.succesRequest(response.data.data);

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.$store.dispatch(
              SHOW_USER_VACATION,
              response.data.data.attributes.profile_id
            );

            Swal.fire(
              "Ok!",
              "La solicitud fue registrada exitosamente",
              "success"
            );
            this.action = "edit";
            this.$router.push({
              name: `holidays`,
              params: {
                example: `myVacations`
              }
            });
          }
        })
        .catch(e => {
          Swal.fire("", "Por favor! intente de nuevo", "error");
          console.log(e);
        });
    },
    updateVacation() {
      const submitButton = this.$refs["buttonRequest"];

      this.form.id = this.getVacation.id;
      this.form.date_init = this.moment(this.range_request.start).format(
        "YYYY-MM-DD hh:mm:ss"
      );
      this.form.date_end = this.moment(this.range_request.end).format(
        "YYYY-MM-DD hh:mm:ss"
      );
      this.form.prueba1 = this.DaysRequest();
      this.form.prueba2 = this.availableDays();
      if (this.form.prueba1 > this.form.prueba2) {
        Swal.fire(
          "Error!",
          "Excediste el limite de días disponibles de vacación",
          "error"
        );

        return;
      }
      this.$store
        .dispatch(UPDATE_VACATION, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.succesRequest(response.data.data);
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.$store.dispatch(
              SHOW_USER_VACATION,
              response.data.data.attributes.profile_id.id
            );
            Swal.fire(
              "Ok!",
              "La solicitud fue actualizada exitosamente",
              "success"
            );
            this.$router.push({
              name: `holidays`,
              params: {
                example: `myVacations`
              }
            });
          }
        })
        .catch(e => {
          Swal.fire("", "Por favor! intente de nuevo", "error");
          console.log(e);
        });
    },
    refreshData() {
      this.action = "create";
      this.range_request.start = "";
      this.range_request.end = "";
      // this.form.date_init = "";
      // this.form.date_init = "";
    },
    deleteRequest() {
      var text;
      var msg;
      text = "Quieres eliminar la solicitud";
      msg = "La solicitud fue eliminado";

      this.form.id = this.getVacation.id;
      Swal.fire({
        title: "Estas seguro?",
        text: `${text}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_VACATION, this.form).then(response => {
            if (response.status == 200) {
              Swal.fire("Eliminado!", msg, "success");
              this.refreshData();
            }
          });
        }
      });
    }
  }
};
</script>
