<template>
  <div>
    <b-card>
      <b-form-group
        id="fieldset-1"
        label="Nombre departamento"
        label-for="input-1"
      >
        <b-form-input
          :class="errors.response ? 'border border-danger' : ''"
          id="input-1"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          type="text"
          placeholder="Ingrese el nombre"
          required
        >
        </b-form-input>
        <b-form-invalid-feedback id="input-1-live-feedback">
          El nombre es requerido
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="this.currentUser.attributes.rol_id === 1"
        id="fieldset-1"
        label="Seleccionar los lideres "
        label-for="input-1"
      >
        <multiselect
          v-if="this.currentUser.attributes.rol_id === 1"
          v-model="leaders"
          :options="users_leaders"
          :multiple="true"
          :group-select="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Seleccione un(os) lider(es)"
          label="name"
          track-by="name"
        >
        </multiselect>
      </b-form-group>
      <b-form-group
        id="fieldset-1"
        label="Seleccionar empleados"
        label-for="input-1"
      >
        <multiselect
          v-model="employees"
          :options="users_employees"
          :multiple="true"
          :group-select="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Seleccione un(os) empleado(s)"
          label="name"
          track-by="name"
        >
        </multiselect>
      </b-form-group>
    </b-card>
    <div class="text-right mt-3">
      <b-button ref="btnSubmit" @click="save" variant="primary" size="sm">
        Añadir
      </b-button>
      <b-button
        class="ml-3"
        variant="secondary"
        size="sm"
        @click="$emit('close-modal-add', false)"
      >
        Cerrar
      </b-button>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
import { LIST_EMPLOYEES_ALL } from "@/services/store/employee.module";
import {
  STORE_DEPARTMENT,
  UPDATE_DEPARTMENT
} from "@/services/store/department.module";

import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  props: ["department", "action"],
  mixins: [validationMixin],
  components: {
    Multiselect
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
  },
  data() {
    return {
      leaders: [],
      leaders_old: [],
      employees: [],
      form: {
        id: this.action === `edit` ? this.department.id : "",
        name: this.action === `edit` ? this.department.attributes.name : "",
        employees: [],
        leaders_old: [],
        employees_aux:
          this.action === `edit` ? this.department.relationships.employees : []
      },
      errors: {}
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  created() {
    if (this.action === `edit`) {
      this.leaders = _.map(
        _.filter(this.form.employees_aux, function(u) {
          return u.attributes.role_id == 2;
        }),
        d => {
          return { id: d.id, name: d.attributes.name };
        }
      );
      this.leaders_old = _.map(
        _.filter(this.form.employees_aux, function(u) {
          return u.attributes.role_id == 2;
        }),
        d => {
          return d.id;
        }
      );
      this.employees = _.map(
        _.filter(this.form.employees_aux, function(u) {
          return u.attributes.role_id == 3;
        }),
        d => {
          return { id: d.id, name: d.attributes.name };
        }
      );
    }
  },
  computed: {
    ...mapGetters(["getEmployeesAll", "currentUser"]),
    users_leaders() {
      let list = _.filter(this.getEmployeesAll, function(u) {
        return u.attributes.role_id == 2;
      });
      return _.map(list, d => {
        return { id: d.id, name: d.attributes.name };
      });
    },
    users_leaders_up() {
      let list = _.filter(this.department.relationships.employees, function(u) {
        return u.attributes.role_id == 2;
      });
      return _.map(list, d => {
        return { id: d.id, name: d.attributes.name };
      });
    },
    users_employees() {
      var list = _.filter(this.getEmployeesAll, function(u) {
        return u.attributes.role_id == 3;
      });
      return _.map(list, d => {
        return { id: d.id, name: d.attributes.name };
      });
    },
    users_employees_up() {
      var list = _.filter(this.department.relationships.employees, function(u) {
        return u.attributes.role_id == 3;
      });
      return _.map(list, d => {
        return { id: d.id, name: d.attributes.name };
      });
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const ACTION =
        this.action === "edit" ? UPDATE_DEPARTMENT : STORE_DEPARTMENT;
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.form.name = this.$v.form.name.$model;
      var employees_aux = _.map(this.employees, d => {
        return d.id;
      });
      var leaders_aux = _.map(this.leaders, d => {
        return d.id;
      });

      if (this.action === "edit") {
        this.form.leaders_old = _.difference(this.leaders_old, leaders_aux);
      }

      this.form.employees = _.concat(leaders_aux, employees_aux);
      await this.$store
        .dispatch(ACTION, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "La accion fue ejecutada exitosamente", "success");
            this.$emit("close-modal-add", false);
          }
        })
        .catch(e => {
          this.errors = e;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("Error!!", "Por favor! intente de nuevo", "error");
        });
    }
  }
};
</script>
