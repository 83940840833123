<template>
  <b-col cols md="12">
    <b-card>
      <b-card-title>
        <b-row>
          <b-col cols md="4" sm="12" xs="12">
            <h4 class="float-left">{{ getShowDepartment.attributes.name }}</h4>
          </b-col>
          <b-col cols md="2" sm="12" xs="12">
            <b-button
              v-if="this.currentUser.attributes.rol_id === 1"
              variant="danger"
              size="sm"
              block
              class="float-left"
              @click="deleteDepartment"
            >
              Eliminar
            </b-button>
          </b-col>
          <b-col cols md="2" sm="12" xs="12">
            <b-button
              v-if="this.currentUser.attributes.rol_id === 1"
              variant="primary"
              block
              size="sm"
              class="float-left"
              @click="showReportDepartment"
            >
              <b-icon icon="file-earmark-medical-fill" font-scale="1"></b-icon>
              Reporte
            </b-button>
          </b-col>
          <b-col cols md="3" sm="12" xs="12">
            <b-button
              v-if="!(this.currentUser.attributes.rol_id === 3)"
              variant="primary"
              block
              size="sm"
              class="float-left"
              @click="openModalEditDepartment"
            >
              <b-icon icon="person-fill" font-scale="1"></b-icon>
              Asignar Personal
            </b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-body>
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group
              label="Filtrar"
              label-for="filter-input"
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Buscar"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Borrar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="5" md="6" class="my-1">
            <b-form-group
              label="Por pagina"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-4"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="7" md="6" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>

        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          stacked="md"
          show-empty
          small
          @filtered="onFiltered"
        >
          <template
            #cell(actions)="row"
            v-if="currentUser.attributes.rol_id !== 3"
          >
            <b-badge
              v-if="currentUser.id !== row.item.id"
              href="#"
              @click="getDepartmentsUser(row.item)"
              variant="dark"
              class="mb-2"
            >
              <b-icon aria-hidden="true" icon="eye" title="Desasignar">
              </b-icon>
              Desasignar
            </b-badge>
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <b-modal
      v-model="showModalAddDepartment"
      id="modal--department"
      hide-footer
      size="xl"
      title="Asignar Departamentos/Empleados/Lideres"
    >
      <div class="d-block">
        <WIDGETASSIGNDEPARTMENT
          :department="department"
          :action="'edit'"
          :leaders_assign="leaders_assign"
          :employees_assign="employees_assign"
          @close-modal-add="closeModalAdd"
        />
      </div>
    </b-modal>
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
import WIDGETASSIGNDEPARTMENT from "@/components/departments-module/widget-add-department";
import Swal from "sweetalert2";
import {
  LIST_DEPARTMENTS,
  UNASSIGN_EMPLOYEE_TO_DEPARTMENT,
  DELETE_DEPARTMENT,
  SHOW_DEPARTMENT
} from "@/services/store/department.module";
export default {
  components: {
    WIDGETASSIGNDEPARTMENT
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
          sortDirection: "desc"
        },
        {
          key: "name",
          label: "Nombre",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "role",
          label: "Rol",
          sortable: true,
          class: "text-center"
        },

        {
          key: "actions",
          label: "Acciones",
          class: "text-right"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      form: {
        id: "",
        employee_id: ""
      },
      showModalAddDepartment: false,
      leaders_assign: [],
      employees_assign: [],
      codesStatus: [200, 201]
    };
  },
  computed: {
    ...mapGetters(["getShowDepartment", "currentUser"]),
    visibleFields() {
      return this.fields.filter(field => field.visible);
    },
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    department() {
      return this.getShowDepartment;
    },
    items() {
      let items = [];
      let employees = [];
      employees = _.orderBy(
        this.getShowDepartment.relationships.employees,
        ["attributes.role_id"],
        ["Asc"]
      );
      for (var idx in employees) {
        let color;
        if (employees[idx].attributes.type === 2) {
          color = `success`;
        } else if (employees[idx].attributes.type === 3) {
          color = `primary`;
        }
        items.push({
          index: parseInt(idx) + 1,
          id: employees[idx].id,
          name: employees[idx].attributes.name,
          role_id: employees[idx].attributes.role_id,
          role: employees[idx].attributes.type,
          _cellVariants: {
            role: color,
            name: color,
            index: color,
            actions: color
          }
        });
      }
      return items;
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    deleteDepartment() {
      Swal.fire({
        title: "<strong>Eliminar Departamento</strong>",
        icon: "info",
        html: "Estas seguro de eliminar el departamento",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_DEPARTMENT, {
              id: this.getShowDepartment.id
            })
            .then(response => {
              if (this.codesStatus.indexOf(response.status) != -1) {
                this.$router.push({
                  name: "departments"
                });
              }
              Swal.close();
            })
            .catch(e => {
              console.error(e.response);
              Swal.close();
              Swal.fire("", "Por favor, intente mas tarde!", "error");
            });
        } else {
          Swal.close();
        }
      });
    },
    showReportDepartment() {
      this.$router.push({
        name: "showReport",
        params: { report: "Reporte" }
      });
    },
    openModalEditDepartment() {
      let list = _.filter(
        this.getShowDepartment.relationships.employees,
        function(u) {
          return u.attributes.role_id == 2;
        }
      );

      this.leaders_assign = _.map(list, d => {
        return { id: d.id, name: d.attributes.name };
      });
      let list2 = _.filter(
        this.getShowDepartment.relationships.employees,
        function(u) {
          return u.attributes.role_id == 3;
        }
      );

      this.employees_assign = _.map(list2, d => {
        return { id: d.id, name: d.attributes.name };
      });
      this.showModalAddDepartment = true;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getDepartmentsUser(user) {
      this.form.employee_id = user.id;
      this.form.id = this.getShowDepartment.id;
      Swal.fire({
        title: "<strong>Desasignar Empleado</strong>",
        icon: "info",
        html: "Estas seguro de desasignar al empleado",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(UNASSIGN_EMPLOYEE_TO_DEPARTMENT, this.form);
          this.$store.dispatch(LIST_DEPARTMENTS);
        }
        Swal.close();
      });
    },
    closeModalAdd(status) {
      this.$store.dispatch(SHOW_DEPARTMENT, this.getShowDepartment.id);
      this.showModalAddDepartment = status;
    }
  }
};
</script>
