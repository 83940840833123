<template>
  <!-- kanban board -->
  <b-row class="mt-6">
    <b-col cols="12">
      <div v-drag-and-drop:options="options">
        <ul class="drag-list">
          <li class="drag-column" v-for="group in groups" :key="group.id">
            <span class="drag-column-header" :style="group.style">
              <h2>{{ group.name }}</h2>
            </span>
            <vue-draggable-group
              v-model="group.items"
              :groups="groups"
              :data-id="group.id"
            >
              <ul class="drag-inner-list" :data-id="group.id" @added="added">
                <li
                  class="drag-item"
                  v-for="item in group.items"
                  :key="item.id"
                  :data-id="item.id"
                  :style="group.style"
                  @click="show(item)"
                >
                  <div class="drag-item-text">{{ item.attributes.title }}</div>
                </li>
              </ul>
            </vue-draggable-group>
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
  <!-- end kanban board -->
</template>
<script>
import { mapGetters } from "vuex";
import { VueDraggableDirective } from "vue-draggable";
import { PURGE_TASK } from "@/services/store/task.module";

var _ = require("lodash");
export default {
  directives: {
    dragAndDrop: VueDraggableDirective
  },
  data() {
    return {
      groups: [
        {
          id: 5,
          name: "Pendientes",
          style: "background: #979797; cursor: pointer;",
          items: []
        },
        {
          id: 6,
          name: "En Progreso",
          style: "background: #2a92bf; cursor: pointer;",
          items: []
        },
        {
          id: 7,
          name: "Revision ",
          style: "background: #f4ce46; cursor: pointer;",
          items: []
        },
        {
          id: 8,
          name: "Finalizada ",
          style: "background: #00b961; cursor: pointer;",
          items: []
        }
      ],
      options: {
        dropzoneSelector: ".drag-inner-list",
        draggableSelector: ".drag-item"
      },
      selectedUser: "",
      users: []
    };
  },
  computed: {
    ...mapGetters(["currentUser", "getTasks"]),
    tasks() {
      return this.getTasks;
    }
  },
  methods: {
    added(evt) {
      const payload = {
        task_id: evt.detail.ids[0],
        status_id: evt.target.dataset.id
      };
      this.$store.dispatch("changeStatus", payload);
    },
    show(task) {
      this.$store.commit("setTask", task);
      // Emito un evento al componente padre, para que muestre el modal con la tarea seleccionada
      this.$emit("show-task", task);
    },
    setValue: function(value) {
      this.users = value;
      this.setGroup();
    },
    listenerRemoveTask(task) {
      for (var j in this.groups) {
        if (task.status_id == this.groups[j].id) {
          this.groups[j].items = _.filter(this.groups[j].items, function(t) {
            return t.id != task.id;
          });
        }
      }
    },
    setGroup() {
      let ts = this.tasks;
      this.groups.map(g => {
        return (g.items = []);
      });
      for (let i in ts) {
        if (this.groups.length > 0) {
          if (this.users.length > 0) {
            for (let j in this.groups) {
              if (ts[i].attributes.status_id == this.groups[j].id) {
                for (let l in this.users) {
                  if (ts[i].attributes.responsible_id === this.users[l]) {
                    this.groups[j].items.push(ts[i]);
                  }
                }
              }
            }
          } else {
            for (let j in this.groups) {
              if (ts[i].attributes.status_id == this.groups[j].id) {
                this.groups[j].items.push(ts[i]);
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.setGroup();
    this.$store.dispatch(PURGE_TASK);
  },
  watch: {
    tasks() {
      this.setGroup();
    }
  }
};
</script>

<style lang="scss">
$ease-out: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
$to-do: #f0f0f0;
$in-progress: #2a92bf;
$review: #f4ce46;
$approved: #00b961;

.drag-list {
  display: flex;
  align-items: flex-start;
  @media (max-width: 690px) {
    display: block;
  }
}

.drag-column {
  flex: 1;
  margin: 0 10px;
  position: relative;
  background: rgba(#ebebeb, 0.2);
  overflow: hidden;

  @media (max-width: 690px) {
    margin-bottom: 30px;
  }

  h2 {
    font-size: 0.8rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
  }

  &-to-do {
    .drag-column-header,
    .drag-options {
      background: $to-do;
    }
  }

  &-in-progress {
    .drag-column-header,
    .drag-options {
      background: $in-progress;
    }
  }

  &-approved {
    .drag-column-header,
    .drag-options {
      background: $approved;
    }
  }
}

.drag-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  user-select: none;
}

.drag-inner-list {
  height: 85vh;
  overflow: auto;
}

.drag-item {
  margin: 10px;
  height: 100px;
  background: rgba(black, 0.4);
  transition: $ease-out;

  /* items grabbed state */
  &[aria-grabbed="true"] {
    background: #5cc1a6;
    color: #fff;
  }

  .drag-item-text {
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }
}

.drag-header-more {
  cursor: pointer;
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}

.item-dropzone-area {
  height: 6rem;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}
</style>
