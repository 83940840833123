<template>
  <div class="col-12">
    <b-card
      title="lista de solicitudes de vacación"
      class="card card-custom card-stretch gutter-b"
      v-if="
        this.currentUser.attributes.rol_id === 1 ||
          this.currentUser.attributes.rol_id === 2
      "
    >
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- ninguno --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Orden inicial"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtrar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Escribe para buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Borrar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Por pagina"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <!-- Main table element -->
      <b-row>
        <b-col lg="12" class="my-1">
          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(days_request)="row">
              {{ dayGet(row.item) }}
            </template>

            <template #cell(actions)="row">
              <b-badge
                href="#"
                @click="redirect(row.item)"
                variant="dark"
                class="mb-2"
              >
                <b-icon
                  aria-hidden="true"
                  icon="eye"
                  title="Ver perfil de empleado"
                >
                </b-icon>
                Estado
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
var moment = require("moment");

// ==========sweetalert2===============
const Swal = require("sweetalert2");

import { SHOW_USER_VACATION } from "@/services/store/holiday.module.js";
import { LIST_VACATIONS_REQUEST } from "@/services/store/holiday.module.js";
import { EMPLOYEE_PROFILE } from "@/services/store/employee.module.js";
import { LIST_HOLIDAYS } from "@/services/store/festivity.module.js";

export default {
  components: {},
  comments: {
    Swal
  },
  props: ["holidays"],
  data() {
    return {
      // sortDirection:
      fields: [
        {
          key: "index",
          sortable: true,
          label: "#"
          // sortDirection: "desc"
        },
        // {
        //   key:"id",
        //   label:"ID"
        // },
        {
          key: "name",
          sortable: true,
          label: "Nombre",
          sortDirection: "desc"
        },
        {
          key: "date_init",
          label: "Fecha inicio",
          class: "text-center"
        },
        {
          key: "date_end",
          label: "Fecha Fin",
          class: "text-center"
        },
        {
          key: "days_request",
          label: "Días solicitados",
          class: "text-center"
        },
        {
          key: "actions",
          label: "Acción"
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: "Ver todo" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getVacationsRequest",
      "getVacation",
      "getHolidays"
    ]),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },

    items() {
      let items = [];
      let employees = [];
      employees = _.orderBy(this.getVacationsRequest, ["id"], ["desc"]);
      for (var idx in employees) {
        items.push({
          index: parseInt(idx) + 1,

          id: employees[idx].id,
          profile_id: employees[idx].attributes.profile_id,
          name: employees[idx].relationships.user.attributes.name,
          date_request:
            employees[idx].attributes.date_init +
            " al " +
            employees[idx].attributes.date_end,
          date_init: employees[idx].attributes.date_init,
          date_end: employees[idx].attributes.date_end,
          jobs: employees[idx].relationships.jobs
        });
      }
      return items;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },

  beforeMount() {
    this.$store.dispatch(LIST_VACATIONS_REQUEST);
    this.$store.dispatch(LIST_HOLIDAYS);
  },
  methods: {
    dayGet(date) {
      let fechas = [];
      this.holidays.forEach(value => {
        fechas.push(moment(value.date_calendar).format("YYYY, M, DD"));
      });
      var from = moment(date.date_init),
        to = moment(date.date_end),
        days = 0;

      while (!from.isAfter(to)) {
        var aux = _.find(fechas, function(e) {
          return e == moment(from).format("YYYY, M, DD");
        });
        // Si no es sabado ni domingo
        if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
          days++;
        }
        from.add(1, "days");
      }
      return days;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    redirect(profile) {
      this.$store.dispatch(EMPLOYEE_PROFILE, profile.profile_id);

      this.$store
        .dispatch(SHOW_USER_VACATION, profile.profile_id)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            // if (response.data) {
            //   action = "edit";
            // }
            let fechas = [];
            this.getHolidays.forEach(value => {
              fechas.push(moment(value.date_calendar).format("YYYY, M, DD"));
            });
            this.$router.push({
              name: `showStatusVacation`,
              params: {
                vacation: profile.profile_id,
                //action: action,
                request: this.getVacation,
                dateHoliday: fechas
              }
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
