<template>
  <div>
    <b-row
      v-if="getFolders.length > 0 || this.currentUser.attributes.rol_id != 3"
    >
      <b-col cols md="4">
        <b-card>
          <template #header v-if="permission">
            <b-row>
              <b-col cols md="12">
                <b-card
                  class="card-custom card-stretch gutter-b"
                  style='cursor:pointer; background-position: right; background-size: 15%; background-image: url("media/svg/shapes/book.svg");'
                  v-b-modal.modal-folder
                >
                  <b-row>
                    <b-col>
                      <a
                        class="font-weight-normal text-dark-35 text-hover-dark font-size-lg mb-1"
                      >
                        <strong>Añadir Formación</strong>
                      </a>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </template>
          <b-col lg="12" class="my-1">
            <b-form-group
              label="Filtrar"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Buscar"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Borrar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="12" class="my-1">
            <b-form-group
              label="Por pagina"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="12" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
          <hr />
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            select-mode="single"
            responsive="sm"
            ref="selectableTable"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            @row-selected="onRowSelected"
          >
            <template #cell(name)="data">
              {{ data.item.value.name }}
            </template>
            <template #cell(action)="data">
              <WIDGETDROPDOWN
                :data="data.item.action"
                v-if="data.item.action.attributes.owner == currentUser.id"
                @delete-folder="deleteFolder"
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col cols md="8" v-if="showColumn">
        <b-overlay :show="overlay" rounded="md">
          <b-card no-body>
            <b-tabs card>
              <b-tab title="Textos" active>
                <b-row v-show="permission">
                  <b-col cols md="4">
                    <b-card
                      class="card-custom card-stretch gutter-b"
                      style='cursor:pointer; background-position: right; background-size: 20%; background-image: url("media/svg/shapes/pdf.svg");'
                      v-b-modal.modal-formation-doc
                    >
                      <b-row>
                        <b-col>
                          <a
                            class="font-weight-normal text-dark-75 text-hover-dark font-size-lg mb-1"
                          >
                            <strong>Añadir Doc.</strong>
                          </a>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-card-text class="text-center">
                    <b-row v-if="textos.length == 0">
                      <b-col cols md="12">
                        <h5>
                          <img src="media/svg/misc/empty.svg" alt="" />
                          <p class="mt-6">
                            ({{ this.selected.name }}) no tiene textos
                          </p>
                        </h5>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col
                        cols
                        md="auto"
                        v-for="texto in textos"
                        :key="texto.id"
                      >
                        <WIDGETFORMATION :formation="texto" />
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-row>
              </b-tab>
              <b-tab title="Videos">
                <b-row v-show="permission">
                  <b-col cols md="4">
                    <b-card
                      class="card-custom card-stretch gutter-b"
                      style='cursor:pointer; background-position: right; background-size: 20%; background-image: url("media/svg/shapes/video.svg");'
                      v-b-modal.modal-formation-video
                    >
                      <b-row>
                        <b-col>
                          <a
                            class="font-weight-normal text-dark-75 text-hover-dark font-size-lg mb-1"
                          >
                            <strong>Añadir Video</strong>
                          </a>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-card-text class="text-center">
                    <b-row v-if="videos.length == 0">
                      <b-col cols md="12">
                        <h5>
                          <img src="media/svg/misc/empty.svg" alt="" />
                          <p class="mt-6">
                            ({{ this.selected.name }}) no tiene videos
                          </p>
                        </h5>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col
                        cols
                        md="auto"
                        v-for="(video, idx) in videos"
                        :key="idx"
                      >
                        <WIDGETFORMATION :formation="video" />
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-row>
              </b-tab>
              <b-tab title="Test">
                <b-row v-show="permission">
                  <b-col cols md="4">
                    <b-card
                      class="card-custom card-stretch gutter-b"
                      style='cursor:pointer; background-position: right; background-size: 30%; background-image: url("media/svg/shapes/test.svg");'
                      v-b-modal.modal-test
                    >
                      <b-row>
                        <b-col>
                          <a
                            class="font-weight-normal text-dark-75 text-hover-dark font-size-lg mb-1"
                          >
                            <strong>Test</strong>
                          </a>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-card-text class="text-center">
                    <b-row v-if="tests.length == 0">
                      <b-col cols md="12">
                        <h5>
                          <img src="media/svg/misc/empty.svg" alt="" />
                          <p class="mt-6">
                            ({{ this.selected.name }}) no tiene test
                          </p>
                        </h5>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col
                        cols
                        md="auto"
                        v-for="(test, idx) in tests"
                        :key="idx"
                      >
                        <WIDGETFORMATION :formation="test" />
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col v-else>
        <b-card no-body>
          <b-card-text class="text-center">
            <h5>
              <img src="media/svg/misc/empty.svg" />
              <p class="mt-6">Seleccione una formación</p>
            </h5>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols md="12">
        <b-card no-body>
          <b-card-text class="text-center">
            <div class="text-center">
              <img src="media/svg/misc/empty.svg" alt="" />
              <p class="mt-6">No hay formaciones</p>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <WIDGETMODALCREATE v-show="permission" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
var _ = require("lodash");

import WIDGETMODALCREATE from "@/components/formations-module/widget-modal-create";
import WIDGETFORMATION from "@/components/formations-module/formations/widget-formation";
import WIDGETDROPDOWN from "@/components/formations-module/widget-dropdown";

export default {
  components: {
    WIDGETFORMATION,
    WIDGETMODALCREATE,
    WIDGETDROPDOWN
  },
  data() {
    return {
      overlay: false,
      rolesCreate: [1, 2],
      fields: [
        {
          key: "name",
          label: "Formación",
          sortable: true,
          class: "text-center"
        },
        {
          key: "action",
          label: "",
          class: "text-center"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      selectedRow: "",
      showColumn: false
    };
  },
  computed: {
    ...mapGetters(["getFolders", "currentUser", "getFormations"]),
    permission() {
      return this.rolesCreate.indexOf(this.currentUser.attributes.rol_id) != -1;
    },
    permissionEdit(owner) {
      return owner == this.currentUser.id;
    },
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let folders = _.orderBy(this.getFolders, ["id"], ["desc"]);
      for (var i in folders) {
        items.push({
          value: {
            id: folders[i].id,
            name: folders[i].attributes.name
          },
          name: folders[i].attributes.name,
          action: folders[i]
        });
      }
      return items;
    },
    textos() {
      let arr = [];
      if (this.getFormations) {
        arr = _.filter(this.getFormations, f => {
          return f.attributes.type === `application/pdf`;
        });
      }
      return arr;
    },
    videos() {
      let arr = [];
      if (this.getFormations) {
        arr = _.filter(this.getFormations, f => {
          return f.attributes.type === `video/mp4`;
        });
      }
      return arr;
    },
    tests() {
      let arr = [];
      if (this.getFormations) {
        if (this.currentUser.rol_id == 1) {
          arr = _.filter(this.getFormations, f => {
            return f.attributes.type === `test`;
          });
        } else {
          arr = _.filter(this.getFormations, f => {
            return (
              f.attributes.type === `test` &&
              (f.attributes.owner == this.currentUser.id ||
                f.attributes.active == 1)
            );
          });
        }
      }
      return arr;
    }
  },
  beforeMount() {
    this.$store.dispatch("listFolders");
    this.$store.dispatch("listSegments");
    this.$store.dispatch("listDepartments");
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    onRowSelected(items) {
      if (items.length > 0) {
        this.overlay = true;
        const payload = {
          folder_id: items[0].value.id
        };
        this.$store
          .dispatch("listFormations", payload)
          .then(res => {
            if (res.status == 200 || res.status == 201) {
              this.overlay = false;
            }
          })
          .catch(e => {
            console.error(e);
            this.overlay = false;
          });

        this.selected = items[0];
        this.$store.commit(
          "setCurrentlyFormationSelected",
          this.selected.value
        );
        this.showColumn = true;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteFolder() {
      this.showColumn = false;
    }
  }
};
</script>
