<template>
  <b-modal
    id="modal-add-column"
    title="Eliminar nueva columna"
    v-model="modalShow"
  >
    <b-form-group
      id="input-group-1"
      label="Seleccione la columna que quiere eliminar"
      label-for="input-1"
    >
      <multiselect
        v-model="names"
        :options="list"
        multiple
        :group-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Seleccione los empleados"
        label="name"
        track-by="name"
      >
        <span slot="noOptions">Lista vacia, No existe mas columnas.</span>
        <span slot="noResult"
          >Oops! No se encontraron elementos. Considere cambiar la consulta de
          búsqueda.</span
        >
      </multiselect>

      <div v-show="names.length == 0" class="mt-2 text-danger">
        Tiene q seleccionar por lo menos una columna
      </div>
    </b-form-group>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          size="sm"
          class="float-right"
          @click="close"
        >
          Cerrar
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right ml-1 mr-1"
          @click="saveColumn"
        >
          Aceptar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
var _ = require("lodash");

export default {
  props: {
    tableHeader: Array
  },
  data() {
    return {
      names: [],
      modalShow: false
    };
  },
  computed: {
    list() {
      return _.map(this.tableHeader, c => {
        return {
          name: c
        };
      });
    }
  },
  methods: {
    saveColumn() {
      if (this.names.length === 0) {
        return;
      }
      this.modalShow = false;
      this.$emit("delete-column-modal", this.names);
    },
    show() {
      this.names = [];
      this.modalShow = true;

      // this.$bvModal.show("modal-add-column");
    },
    close() {
      this.modalShow = false;

      // this.$bvModal.hide("modal-add-column");
    }
  }
};
</script>

<style></style>vue
