<template>
  <div
    v-if="
      (getDoctype.doctype.attributes.name === 'nominas' &&
        this.currentUser.attributes.rol_id === 1) ||
        (getDoctype.doctype.attributes.name === 'nominas' &&
          this.currentUser.attributes.rol_id === 2) ||
        (getDoctype.doctype.attributes.name === 'valoraciones' &&
          this.currentUser.attributes.rol_id === 1) ||
        (getDoctype.doctype.attributes.name === 'valoraciones' &&
          this.currentUser.attributes.rol_id === 2) ||
        (getDoctype.doctype.attributes.name === 'contratos' &&
          this.currentUser.attributes.rol_id === 1) ||
        (getDoctype.doctype.attributes.name === 'circulares' &&
          this.currentUser.attributes.rol_id === 1)
    "
  >
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-ver"></i>
      </template>
      <div>
        <div class="navi navi-hover min-w-md-250px">
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link" style="cursor:pointer" @click="showModal">
              <span class="navi-icon">
                <b-icon icon="pencil-fill" variant="primary"></b-icon>
              </span>
              <span class="navi-text"> Editar </span>
            </a>
            <a href="#" class="navi-link" @click="deleteF">
              <span class="navi-icon">
                <b-icon icon="dash-circle" variant="danger"></b-icon>
              </span>
              <span class="navi-text">Eliminar</span>
            </a>
          </b-dropdown-text>
        </div>
      </div>
    </b-dropdown>

    <b-modal
      :id="`modal-payroll-${data.id}`"
      hide-footer
      :title="`Documento:  ${data.attributes.name}`"
    >
      <div class="d-block text-center">
        <PAYROLL
          @store-payroll-update="hideModal"
          :doctypeID="this.getBreadcrumbDoctype.id"
          :document="data"
          :action="`edit`"
          v-if="data.type === `documents`"
        />
      </div>
    </b-modal>
    <b-modal
      :id="`modal-circular-${data.id}`"
      hide-footer
      :title="`Documento:  ${data.attributes.name}`"
    >
      <div class="d-block text-center">
        <CIRCULAR
          @store-circular-update="hideModal"
          :doctypeID="this.getBreadcrumbDoctype.id"
          :document="data"
          :action="`edit`"
          v-if="data.type === `documents`"
        />
      </div>
    </b-modal>
    <b-modal
      :id="`modal-contract-${data.id}`"
      hide-footer
      :title="`Documento:  ${data.attributes.name}`"
    >
      <div class="d-block text-center">
        <CONTRACT
          @store-contract-update="hideModal"
          :doctypeID="this.getBreadcrumbDoctype.id"
          :document="data"
          :action="`edit`"
          v-if="data.type === `documents`"
        />
      </div>
    </b-modal>
    <b-modal
      :id="`modal-assessment-${data.id}`"
      hide-footer
      :title="`Documento:  ${data.attributes.name}`"
      size="xl"
    >
      <div class="d-block text-center">
        <ASSESSMENT
          @store-assessment-update="hideModal"
          :doctypeID="this.getBreadcrumbDoctype.id"
          :document="data"
          :action="`edit`"
          v-if="data.type === `documents`"
        />
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import PAYROLL from "@/components/employees-module/profiles/documents/payrolls/payroll";
import CIRCULAR from "@/components/employees-module/profiles/documents/circulares/circular";
import CONTRACT from "@/components/employees-module/profiles/documents/contracts/contract";
import ASSESSMENT from "@/components/employees-module/profiles/documents/ratings/assessment";

import Swal from "sweetalert2";
import {
  LIST_DOCUMENTS,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_CHILDREN
} from "@/services/store/document.module";
export default {
  props: ["data"],
  components: {
    PAYROLL,
    CIRCULAR,
    CONTRACT,
    ASSESSMENT
  },
  computed: {
    ...mapGetters([
      "getBreadcrumbDoctype",
      "currentUser",
      "getDoctype",
      "getEmployeeProfile",
      "currentUser"
    ])
  },
  methods: {
    hideModal(value) {
      this.$nextTick(() => {
        this.$bvModal.hide(value);
      });
    },
    showModal() {
      if (this.getDoctype.doctype.id == 1) {
        this.$bvModal.show(`modal-payroll-${this.data.id}`);
      } else if (this.getDoctype.doctype.id == 2) {
        this.$bvModal.show(`modal-circular-${this.data.id}`);
      } else if (this.getDoctype.doctype.id == 3) {
        this.$bvModal.show(`modal-contract-${this.data.id}`);
      } else {
        this.$bvModal.show(`modal-assessment-${this.data.id}`);
      }
    },
    deleteF() {
      var text;
      var action;
      var msg;
      if (this.data.type === `documents`) {
        text = "Quieres eliminar el documento";
        action = this.getBreadcrumbDoctype.id
          ? DELETE_DOCUMENT_CHILDREN
          : DELETE_DOCUMENT;
        msg = "El documento fue eliminado";
      }
      Swal.fire({
        title: "Estas seguro?",
        text: `${text}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(action, this.data).then(response => {
            if (response.status == 200) {
              this.$socket.emit("DELETE_DOCUMENT_CIRCULAR", this.data);
              this.$store.dispatch(LIST_DOCUMENTS, {
                doctype: this.data.attributes.doc_type_id,
                employee_id: this.getEmployeeProfile.id
              });
              Swal.fire("Eliminado!", msg, "success");
            }
          });
        }
      });
    },
    active() {
      this.$emit("listener-active");
    }
  },
  mounted() {}
};
</script>
