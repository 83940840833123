<template>
  <div class="col-12">
    <b-card
      title="Perfil del Empleado"
      class="card card-custom card-stretch gutter-b"
    >
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Nombre: "
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <label label-cols-sm="7">{{ name }}</label>
          </b-form-group>
          <b-form-group
            label="Email: "
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <label label-cols-sm="7">{{ email }}</label>
          </b-form-group>
          <b-form-group
            label="Rol:"
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <label label-cols-sm="7">{{ rol }}</label>
          </b-form-group>
          <b-form-group
            label="Departamentos:"
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <p label-cols-sm="12" v-for="(d, i) in jobs" :key="i">
              {{ d.name }}
            </p>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group>
            <CONTENTEMPLOYEES :profile="this.getEmployeeProfile" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CONTENTEMPLOYEES from "@/components/employees-module/profiles/widget-content";
import { mapGetters } from "vuex";

export default {
  components: {
    CONTENTEMPLOYEES
  },
  computed: {
    ...mapGetters(["getEmployeeProfile"]),
    name() {
      return this.getEmployeeProfile.attributes.name;
    },
    email() {
      return this.getEmployeeProfile.attributes.email;
    },
    rol() {
      return this.getEmployeeProfile.attributes.type;
    },
    jobs() {
      return this.getEmployeeProfile.relationships.jobs;
    }
  },
  beforeMount() {},
  data() {
    return {};
  },

  methods: {},
  mounted() {}
};
</script>
