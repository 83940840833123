<template>
  <div>
    <div>
      <WIDGETATTACHMENTPREVIEW :files="files" @delete-file="deleteFile" />
    </div>
    <b-progress :max="max" v-if="load" class="mb-4" style="margin-bottom: 5px">
      <b-progress-bar
        :value="this.loading"
        :label="`${this.loading}%`"
      ></b-progress-bar>
    </b-progress>
    <form @submit.prevent="sendMessage">
      <b-input-group class="mt-3">
        <b-form-input
          :id="`textarea-${moment()}`"
          placeholder="Escriba el mensaje"
          rows="1"
          v-model="message"
          max-rows="2"
          @keyup.enter="sendMessage"
          @click="typingMessage"
          @change="typingMessage"
          :disabled="isChat"
        ></b-form-input>
        <!-- <b-form-input type="text" v-model="form.message"></b-form-input> -->
        <b-input-group-append>
          <b-button
            :disabled="isChat"
            variant="success"
            @click="
              $refs.fileInput2.$el.querySelector('input[type=file]').click()
            "
          >
            <b-icon icon="paperclip" aria-hidden="true"></b-icon
          ></b-button>
          <b-form-file
            multiple
            v-model="attachments"
            style="display: none"
            ref="fileInput2"
            @change="addFiles"
          />
          <b-button
            type="submit"
            variant="primary"
            :id="sendAttachmentMessage"
            :disabled="isChat"
          >
            <b-spinner small type="grow" v-if="this.spinner.add"></b-spinner>

            {{ this.action === `edit` ? "Actualizar" : "Enviar" }}</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import aws from "@/utils/config";
import md5 from "md5";
import moment from "moment";
import WIDGETATTACHMENTPREVIEW from "@/components/chats-module/attachment/widget-attachment-preview";
var _ = require("lodash");

export default {
  computed: {
    ...mapGetters(["currentUser", "getChat"]),
    isChat() {
      let chat = this.getChat.id;
      let aux = _.findIndex(this.currentUser.relationships.chat_user, function(
        o
      ) {
        return o.pivot.chat_id == chat;
      });
      return aux != -1 ? false : true;
    }
  },
  components: {
    WIDGETATTACHMENTPREVIEW
  },
  props: ["msg", "action"],

  data() {
    return {
      sendAttachmentMessage: `sendAttachmentMessage${moment().valueOf()}`,
      message: this.action === `edit` ? this.msg.attributes.message : "",
      form: {
        id: this.action === `edit` ? this.msg.id : "",
        parent_id:
          this.action === `edit`
            ? this.msg.attributes.parent_id
            : this.action === `answer`
            ? this.msg.id
            : 0,
        message: this.action === `edit` ? this.msg.attributes.message : "",
        type: this.action === `edit` ? this.msg.attributes.type : "",
        read: 0,
        created_by:
          this.action === `edit` ? this.msg.attributes.created_by : "",
        user: "",
        messageable_id:
          this.action === `edit`
            ? this.msg.attributes.messageable_id
            : this.action === `answer`
            ? this.msg.attributes.messageable_id
            : "",
        messageable_type: "App/Models/Chat",
        attachments: [],
        unread_messages: 0
      },
      handleHide: false,
      attachments: [],
      files: [],
      urls: [],
      spinner: {
        attachment: false
      },
      loading: 0,
      load: false,
      max: 100
      // ,
    };
  },
  methods: {
    typingMessage() {
      this.$socket.emit("VIEW_CHAT_USER", {
        user_id: this.currentUser.id,
        chat_id: this.getChat.id
      });
      if (this.message != "") {
        this.$socket.emit("TYNPING_MESSAGE", {
          user: this.currentUser.attributes.name,
          chat: this.getChat.id
        });
      }
    },

    async sendMessage(e) {
      if (!this.message) {
        if (this.attachments.length === 0) {
          return;
        }
      }
      e.preventDefault();
      this.form.created_by = this.currentUser.id;
      this.form.message = this.message;

      this.form.messageable_id = this.getChat.id;
      this.form.type = "text";
      let unread = _.find(this.currentUser.relationships.chat_user, cu => {
        return cu.pivot.chat_id === this.getChat.id;
      });
      this.form.unread_messages = unread.pivot.unread_messages;
      if (this.attachments) {
        await this.sendAttachments();
      }

      if (this.action === "create" || this.action === "answer") {
        this.$socket.emit("SEND_MESSAGE", this.form);
      } else if (this.action === "edit") {
        this.$socket.emit("UPDATE_MESSAGE", this.form);
      }
      this.overlay = false;
      this.spinner.attachment = false;

      this.message = "";
      this.attachments = [];
      this.files = [];
    },
    async sendAttachments() {
      this.overlay = true;
      this.spinner.attachment = true;
      var element = document.getElementById(this.sendAttachmentMessage);
      element.setAttribute("disabled", "true");

      if (this.attachments) {
        var promises = [];
        let files = this.attachments;
        for (var idx in files) {
          promises.push(this.s3upload(files[idx]));
        }

        let urls = await Promise.all(promises);
        this.form.attachments = urls;
        element.removeAttribute("disabled");
      }
    },
    getFileName(file) {
      let an = file.name.split(".");
      return md5(`${file.name}-${Date.now()}`) + "." + an[an.length - 1];
    },
    async s3upload(file) {
      var fileName = file.name;
      this.load = true;
      const payload = {
        Bucket: `${process.env.VUE_APP_BUCKETNAME}`,
        Key: `employees/${this.currentUser.attributes.name}/chats/${this.getChat.attributes.name}/${fileName}`,
        Body: file,
        ACL: "public-read"
      };
      return new Promise((resolve, reject) => {
        aws
          .s3()
          .upload(payload)
          .on("httpUploadProgress", evt => {
            var uploaded = Math.round((evt.loaded / evt.total) * 100);
            this.loading = uploaded;
          })
          .send((err, data) => {
            if (err) {
              reject(err.stack);
            }
            if (data.Location) {
              this.load = false;
              resolve({
                name: file.name,
                type: file.type,
                url: data.Location
              });
            }
          });
      });
    },
    deleteFile(file) {
      var i = this.attachments.indexOf(file);
      this.attachments.splice(i, 1);
    },
    addFiles(e) {
      if (this.attachments) {
        var list = e.target.files;
        list.forEach(element => {
          this.files.push(element);
        });
      }
    },
    isTypeFile(name) {
      var n = name.split("/");

      return n[1];
    }
  }
};
</script>
