<template>
  <WIDGETTASK />
</template>
<script>
import WIDGETTASK from "@/components/tasks-module/widget-task";
export default {
  components: {
    WIDGETTASK
  },
  beforeCreate() {
    this.$store.dispatch("listCustomers");
  }
};
</script>
