<template>
  <b-row class="mt-6">
    <b-col cols="auto" class="mr-auto">
      <b-button v-b-modal.modal-task variant="primary"
        ><b-icon icon="plus"></b-icon> Tarea
      </b-button>
    </b-col>
    <b-modal id="modal-task" size="lg" title="Nueva Tarea" hide-footer>
      <b-overlay :show="show" rounded="md">
        <b-form>
          <b-form-group id="input-group-1" label-for="input-1">
            <label for="">
              Titulo de la tarea
              <span style="color: red"> (requerido) </span></label
            >
            <b-form-input
              id="input-1"
              v-model="$v.form.title.$model"
              :state="validateState('title')"
              type="text"
              required
              placeholder="Titulo de la tarea"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              El titulo de la tarea es requerido
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="input-group-2" label-for="input-2">
            <label for="">
              Descripcion de la tarea
              <span style="color: red"> (requerido) </span></label
            >
            <b-form-textarea
              id="textarea-no-resize"
              placeholder="Descripción"
              rows="3"
              no-resize
              v-model="$v.form.description.$model"
              :state="validateState('description')"
            />
            <b-form-invalid-feedback id="input-2-live-feedback">
              La descripcion de la tarea es requerida
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="showInThisRoute" id="input-group-3">
            <label for="datepicker-sm"
              >Empleado <span style="color: red">(requerido) </span>
            </label>
            <multiselect
              :options="employees"
              v-model="$v.form.responsible.$model"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Seleccione el responsable de la tarea"
              label="text"
              track-by="id"
              :state="validateState('responsible')"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} seleccionado</span
                ></template
              >
            </multiselect>

            <span style="color: red" v-if="this.errors.employee">
              Debes seleccionar un responsable
            </span>
          </b-form-group>

          <b-form-group id="input-group-4">
            <label for="datepicker-sm">
              Cliente
              <span style="color: red"> (requerido) </span></label
            >
            <multiselect
              v-model="$v.form.customer.$model"
              :options="customers"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Seleccione el cliente"
              label="text"
              track-by="text"
              :state="validateState('customer')"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} seleccionado</span
                ></template
              >
            </multiselect>

            <span style="color: red" v-if="this.errors.customer">
              Debes seleccionar un cliente
            </span>
          </b-form-group>

          <b-form-group>
            <label for="datepicker-sm">Fecha de entrega (opcional)</label>
            <b-form-datepicker
              id="datepicker-sm"
              size="sm"
              locale="en"
              class="mb-2"
              v-model="form.date_end"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group label="Archivo(s) (opcional)" label-for="input-3">
            <b-form-file
              multiple
              :file-name-formatter="formatNames"
              v-model="form.attachment"
              placeholder="Subir un archivo"
              drop-placeholder="Arrastrar archivo aquí..."
            />
          </b-form-group>
          <b-progress
            :max="max"
            v-if="load"
            class="mb-4"
            style="margin-bottom: 5px"
          >
            <b-progress-bar
              :value="this.loading"
              :label="`${this.loading}%`"
            ></b-progress-bar>
          </b-progress>

          <b-button
            type="button"
            id="btnSubmit"
            ref="btnSubmit"
            variant="primary"
            @click="onSubmit"
            class="float-right"
          >
            <b-spinner small type="grow" v-if="this.spinner.add"></b-spinner>
            Guardar</b-button
          >
        </b-form>
      </b-overlay>
    </b-modal>
    <b-modal
      id="modal-log-tasks"
      ref="modal-log-tasks"
      size="lg"
      title="Actividades de las tareas"
    >
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Initial sort"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtrar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Borrar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            label="Filtro activado"
            description="Deje todo sin marcar para filtrar todos los datos"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <b-form-checkbox value="user">Empleado</b-form-checkbox>
              <b-form-checkbox value="task">Tarea</b-form-checkbox>
              <b-form-checkbox value="status">Status</b-form-checkbox>
              <b-form-checkbox value="time">Hora</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Por pagina"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <!-- Table log tasks -->
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
      >
        <template #cell(name)="row">
          {{ row.value }}
        </template>
      </b-table>
      <!-- end Table log tasks -->

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleHide"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-row>
  <!-- end add task -->
</template>
<script>
import { mapGetters } from "vuex";
import { STORE_TASK } from "@/services/store/task.module";
import aws from "@/utils/config";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

var _ = require("lodash");

export default {
  mixins: [validationMixin],
  components: {
    Multiselect
  },
  data() {
    return {
      form: {
        description: "",
        title: "",
        responsible: "",
        customer: [],
        date_end: "",
        attachment: [],
        departmentId: ""
      },
      errors: {
        employee: false,
        customer: false
      },
      validationSelect: true,
      show: false,
      overlay: false,
      fields: [
        {
          key: "task",
          label: "Tarea",
          sortable: true,
          class: "text-center"
        },
        {
          key: "user",
          label: "Empleado",
          sortable: true,
          class: "text-center"
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "text-center"
        },
        {
          key: "time",
          label: "Hora",
          sortable: true,
          class: "text-center"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [15, 20, 50, 100, 500, 1000],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      loading: 0,
      load: false,
      max: 100,
      spinner: {
        add: false
      },
      doNotShowInTheseRoutes: ["showTasksUser", "profileEmployee"]
    };
  },
  validations: {
    form: {
      title: {
        required
      },
      description: {
        required
      },
      responsible: {
        required
      },
      customer: {
        required
      }
    }
  },
  computed: {
    ...mapGetters([
      "getEmployees",
      "currentUser",
      "getEmployeeProfile",
      "getLogTasks",
      "getDepartmentID",
      "getAllCustomers"
    ]),
    showInThisRoute() {
      let show = true;
      if (this.doNotShowInTheseRoutes.indexOf(this.$route.name) != -1) {
        show = false;
        return;
      }
      return show;
    },
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let logs = _.orderBy(this.getLogTasks, ["time"], ["desc"]);
      for (var idx in logs) {
        items.push({
          user: logs[idx].user,
          task: logs[idx].task,
          status: logs[idx].status,
          time: logs[idx].time
        });
      }
      return items;
    },
    employees() {
      return _.map(this.getEmployees, e => {
        return { value: e.id, text: e.name };
      });
    },
    customers() {
      return _.map(this.getAllCustomers, e => {
        return { value: e.id, text: `${e.dni_nie} (${e.name})` };
      });
    },
    department() {
      return this.getDepartmentID;
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} archivos seleccionados`;
    },
    onFiltered(filteredItems) {
      // Activar la paginación para actualizar el número de botones / páginas debido al filtrado
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleHide() {
      this.$refs["modal-log-tasks"].hide();
    },
    showLogTasks() {
      this.$store
        .dispatch("listLogTasks", this.getDepartmentID)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$refs["modal-log-tasks"].show();
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    async onSubmit() {
      this.overlay = true;
      this.spinner.add = true;

      var btn = document.getElementById(`btnSubmit`);
      btn.setAttribute("disabled", "true");

      switch (this.$route.name) {
        case "profileEmployee":
          this.form.responsible = this.getEmployeeProfile.id;
          break;
        case "showTasksUser":
          this.form.responsible = this.currentUser.id;
          break;
        default:
          this.form.responsible = this.form.responsible.value;
          break;
      }

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.errors.employee = this.$v.form.responsible.$anyError;
        this.errors.customer = this.$v.form.customer.$anyError;
        this.overlay = false;
        this.spinner.add = false;
        btn.removeAttribute("disabled");
        return;
      }
      this.form.owner = this.currentUser.id;
      this.form.customer = this.form.customer.value;
      if (this.form.attachment) {
        var promises = [];
        let files = this.form.attachment;
        for (var idx in files) {
          promises.push(this.s3upload(files[idx]));
        }

        let urls = await Promise.all(promises);
        this.form.attachment = urls;
      }
      this.form.departmentId = this.department;
      const ACTION = STORE_TASK;
      this.$store
        .dispatch(ACTION, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.overlay = false;
            this.spinner.add = false;
            this.form.attachment = [];
            btn.removeAttribute("disabled");
            this.$bvModal.hide("modal-task");
            Swal.fire("Ok!", "Se creo la tarea exitosamente", "success");
          }
        })
        .catch(e => {
          console.error(e);
          this.spinner.add = false;
          btn.removeAttribute("disabled");
          this.overlay = false;
          Swal.fire("", "Por favor! intente de nuevo", "error");
        });
    },
    async s3upload(file) {
      this.load = true;
      const payload = {
        Bucket: `${process.env.VUE_APP_BUCKETNAME}`,
        Key: `employees/${this.form.title}/tasks/${this.form.responsible.text}/${file.name}`,

        Body: file,
        ACL: "public-read"
      };
      return new Promise((resolve, reject) => {
        aws
          .s3()
          .upload(payload)
          .on("httpUploadProgress", evt => {
            var uploaded = Math.round((evt.loaded / evt.total) * 100);
            this.loading = uploaded;
          })
          .send((err, data) => {
            if (err) {
              reject(err.stack);
            }
            if (data.Location) {
              this.load = false;
              resolve({
                name: file.name,
                type: file.type,
                url: data.Location
              });
            }
          });
      });
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  }
};
</script>
