<template>
  <div>
    <b-row>
      <b-col>
        <div>
          <h5 class="float-left pt-2">
            {{ msg.relationships.createdBy.name }}
          </h5>
          <a
            v-b-tooltip.hover="{ variant: 'info' }"
            :title="
              `${moment(msg.attributes.created_at).format(
                'dddd DD MMMM YYYY'
              )} a las ${moment(msg.attributes.created_at).format('h:mm:ss')} `
            "
            class="float-left pl-2 pt-2"
          >
            <strong>{{
              moment(msg.attributes.created_at)
                .startOf("minutes")
                .fromNow()
            }}</strong>
          </a>
        </div>
      </b-col>
      <b-col
        md="12"
        :class="
          !msg.attributes.state ||
          (msg.attributes.message == '' &&
            msg.relationships.attachments.length == 0)
            ? 'text-muted'
            : ''
        "
      >
        <template>
          {{
            msg.attributes.state
              ? msg.attributes.message == "" &&
                msg.relationships.attachments.length == 0
                ? "Archivos Eliminado"
                : msg.attributes.message
              : "Mensaje eliminado"
          }}</template
        >
        <template v-if="msg.attributes.state">
          <WIDGETLISTATTACHMENT :msg="msg" />
          <div class="accordion" role="tablist">
            <b-badge
              style="cursor: pointer"
              v-if="msg.attributes.parent_id === 0"
              v-b-toggle="`accordion-message-${index}-${msg.id}`"
              class="mt-2 text-muted"
              pill
              variant="outline-primary"
              ><strong>Responder</strong></b-badge
            >
            <b-badge
              style="cursor: pointer"
              v-if="msg.attributes.created_by === currentUser.id"
              v-b-toggle="`accordion-edit-${msg.id}`"
              class="mt-2 ml-1 text-muted"
              pill
              variant="outline-primary"
              @click="action = 'edit'"
              ><strong>Editar</strong></b-badge
            >
            <b-badge
              v-if="msg.attributes.created_by === currentUser.id"
              @click="deleteMessage(msg)"
              class="mt-2 ml-1 text-muted"
              pill
              variant="outline-primary"
              style="cursor: pointer"
              ><strong>Eliminar</strong></b-badge
            >
            <template v-if="msg.relationships.children.length > 0">
              <b-badge
                pill
                variant="outline-primary"
                v-b-toggle="`accordion-message-${index}-${msg.id}`"
                class="mt-2 pl-3 text-muted"
                v-if="msg.relationships.children"
                ><strong
                  >{{ msg.relationships.children.length }} respuesta(s)</strong
                ></b-badge
              >
            </template>
          </div>
        </template>
      </b-col>
    </b-row>
    <b-collapse
      v-if="msg.attributes.state"
      accordion="my-accordion"
      role="tabpanel"
      :id="`accordion-message-${index}-${msg.id}`"
      class="gap-0"
    >
      <WIDGETCHILDRENMESSAGE ref="witgetMessageChildren" :message="msg" />
      <FORMADDMESSAGE :msg="msg" :action="'answer'" />
    </b-collapse>
    <b-collapse
      accordion="my-accordion"
      role="tabpanel"
      :id="`accordion-edit-${msg.id}`"
      class="mt-2"
    >
      <FORMADDMESSAGE :msg="msg" :action="'edit'" />
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FORMADDMESSAGE from "@/components/chats-module/form/form-add-message";
import WIDGETCHILDRENMESSAGE from "@/components/chats-module/message/widget-children-message";
import WIDGETLISTATTACHMENT from "@/components/chats-module/attachment/widget-attachment";
const Swal = require("sweetalert2");

import { DELETE_MESSAGE } from "@/services/store/chat.module";

export default {
  computed: {
    ...mapGetters(["currentUser"])
  },
  components: {
    FORMADDMESSAGE,
    WIDGETCHILDRENMESSAGE,
    WIDGETLISTATTACHMENT
  },
  comments: {
    Swal
  },
  props: ["msg", "index"],
  data() {
    return {
      action: "create"
    };
  },
  methods: {
    getName(name) {
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },
    deleteMessage(file) {
      Swal.fire({
        title: "¿Estas seguro de eliminar el mensaje?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, eliminar mensaje"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_MESSAGE, file)
            .then(response => {
              Swal.fire(
                "Exito!",
                "El mensaje fue eliminado con exito.",
                "success"
              );
              this.$socket.emit("DELETE_MESSAGE", response.data.data);

              this.$emit("delete-message");

              // this.$store.dispatch(LIST_EMPLOYEES_ALL);
            })
            .catch(e => {
              console.error(e);
              Swal.fire("Error!", "El mensaje no fue eliminado.", "danger");
            });
          // Swal.fire("Exito!", "El mensaje fue emilinado", "success");
          // Swal.fire("Deleted!", "El empleado fue dado de baja.", "success");
        }
      });
    }
  }
};
</script>
