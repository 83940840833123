<template>
  <b-col cols="12">
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-3"
          id="kt_wizard_v3"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
            <div class="col-xl-12 col-xxl-12">
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <b-row>
                  <b-col cols>
                    <b-card
                      :title="data.attributes.name"
                      :sub-title="
                        `Creado por el usuario: ${data.attributes.owner_name}`
                      "
                      class="card card-custom card-stretch gutter-b"
                      :footer="data.attributes.created"
                      footer-class="text-muted font-weight-bold font-size-lg flex-grow-1"
                    >
                      <div class="card-body d-flex flex-column">
                        <div class="flex-grow-1 pb-5">
                          <h4>Descripción</h4>
                          <p
                            class="text-dark-50 font-weight-normal font-size-lg mt-1"
                          >
                            {{ data.attributes.description }}
                          </p>
                          <hr />
                          <PDF
                            :url="data.attributes.url"
                            v-if="data.attributes.type === 'application/pdf'"
                          />

                          <b-row v-if="data.attributes.type === 'xlsx'">
                            <b-col md="6" class="mb-6">
                              <b-button
                                variant="success"
                                @click="exportAsExcel"
                              >
                                Export Excel
                              </b-button>
                            </b-col>

                            <b-col cols md="12">
                              <vue-excel-editor
                                v-if="data.attributes.type === 'xlsx'"
                                v-model="data.attributes.url"
                                no-footer
                                readonly
                                :page="10"
                                ref="grid"
                              >
                                <vue-excel-column
                                  field="av"
                                  label="Aspecto a valorar"
                                  type="string"
                                  width="250px"
                                />
                                <vue-excel-column
                                  field="rating"
                                  label="Valoración (1 al 10)"
                                  type="number"
                                  width="150px"
                                  summary="sum"
                                />
                                <vue-excel-column
                                  field="comment"
                                  label="Comentarios"
                                  type="string"
                                  width="250px"
                                />
                              </vue-excel-editor>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
              <div class="pb-5" data-wizard-type="step-content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { mapGetters } from "vuex";
import PDF from "@/components/formations-module/pdf/pdf";
import { USER_DOCUMENT_VIEW } from "@/services/store/document.module.js";

export default {
  props: ["data"],
  components: {
    PDF
  },
  computed: {
    ...mapGetters([
      "getDocument",
      "currentUser",
      "getLog",
      "getBreadcrumbDoctype"
    ])
  },
  data() {
    return {
      isWatching: true,
      showModal: false,
      answered: [],
      resolved: false,
      json_data: []
    };
  },
  created() {
    window.addEventListener("beforeunload", this.handler2);
  },
  methods: {
    handler() {
      this.showModal = !this.showModal;
    },
    handler2: function handler(event) {
      console.log(event);
      this.$router.push("/");
    },
    setResolved() {
      this.resolved = !this.resolved;
    },
    setAnswers(value) {
      this.answered = value;
    },
    cameout() {
      if (this.resolved && this.data.attributes.type === `documents`) {
        return new Promise((resolve, reject) => {
          this.resolved = !this.resolved;
          const payload = {
            id: this.getLog,
            action: 2
          };
          if (!this.getLog) {
            reject("error");
          }
          resolve(payload);
        });
      }
    },
    exportAsExcel() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = `${this.data.attributes.name} `;
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    }
  },
  mounted() {
    this.sockets.subscribe("DOCUMENT_USER_VIEW", data => {
      if (data.user_id == this.currentUser.id) {
        this.$store.dispatch(USER_DOCUMENT_VIEW, data);
        this.$store.commit("userDocumentView", data);
      }
    });
  }
};
</script>
