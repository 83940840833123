<template>
  <div class="header flex-column header-fixed" v-if="!isAdmin">
    <div class="header-top" style="margin-top: 0px !important">
      <div class="container">
        <b-navbar toggleable="lg" type="dark" variant="primary">
          <b-navbar-brand class="ml-4">
            <router-link :to="{ name: 'dashboard' }">
              <img
                alt="Logo"
                :src="layoutConfig('self.logo.default')"
                class="max-h-50px"
              />
            </router-link>
          </b-navbar-brand>

          <b-navbar-toggle
            target="nav-collapse"
            class="ml-auto"
          ></b-navbar-toggle>

          <b-collapse
            id="nav-collapse"
            is-nav
            :class="
              widthFluid ? `header-tabs nav align-self-end font-size-lg` : ``
            "
          >
            <b-navbar-nav>
              <li class="nav-item">
                <router-link :to="{ name: 'dashboard' }">
                  <a
                    :class="
                      this.$route.name === 'dashboard'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="0"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                    >Inicio</a
                  >
                </router-link>
              </li>
            </b-navbar-nav>
            <b-navbar-nav>
              <li class="nav-item mr-3">
                <router-link :to="{ name: 'formations' }">
                  <a
                    :class="
                      this.$route.path === '/formaciones/*' ||
                      this.$route.path === '/folders/*'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="1"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                  >
                    Formaciones
                  </a>
                </router-link>
              </li>
            </b-navbar-nav>
            <b-navbar-nav>
              <li class="nav-item mr-3" v-if="permissions">
                <router-link :to="{ name: 'activities' }">
                  <a
                    :class="
                      this.$route.path === '/actividades/*'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="1"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                  >
                    Actividad
                  </a>
                </router-link>
              </li>
            </b-navbar-nav>
            <b-navbar-nav v-if="permissions">
              <li class="nav-item mr-3">
                <router-link :to="{ name: 'tasks' }">
                  <a
                    :class="
                      this.$route.path === '/tareas/*'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="1"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                  >
                    Tareas
                  </a>
                </router-link>
              </li>
            </b-navbar-nav>
            <b-navbar-nav v-else>
              <li class="nav-item mr-3">
                <a
                  :class="
                    this.$route.path === '/tareas/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6'
                  "
                  @click="showTasksUser()"
                  data-tab="1"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  aria-selected="true"
                >
                  Mis tareas
                </a>
              </li>
            </b-navbar-nav>
            <b-navbar-nav>
              <li class="nav-item mr-3" v-if="permissions">
                <router-link :to="{ name: 'employees' }">
                  <a
                    :class="
                      this.$route.path === '/empleados/*'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="1"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                  >
                    Empleados
                  </a>
                </router-link>
              </li>
            </b-navbar-nav>
            <b-navbar-nav>
              <li class="nav-item mr-3">
                <router-link :to="{ name: 'departments' }">
                  <a
                    :class="
                      this.$route.path === '/departamentos/*'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="1"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                  >
                    Departamentos
                  </a>
                </router-link>
              </li>
            </b-navbar-nav>
            <b-navbar-nav>
              <li class="nav-item mr-3">
                <router-link
                  :to="{
                    name: 'holidays',
                    params: {
                      example: 'listVacations'
                    }
                  }"
                >
                  <a
                    :class="
                      this.$route.path === '/vacaciones/*'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="1"
                    data-toggle="tab"
                    href="#"
                    role="tab"
                    aria-selected="true"
                  >
                    {{
                      this.currentUser.attributes.rol_id == 3
                        ? "Mis Vacaciones"
                        : "Vacaciones"
                    }}
                  </a>
                </router-link>
              </li>
            </b-navbar-nav>
            <b-navbar-nav>
              <li class="nav-item mr-3">
                <router-link
                  :to="{
                    name: 'chats',
                    params: {
                      chat: chatId //codificar el id
                    }
                  }"
                >
                  <a
                    :class="
                      this.$route.path === '/chats/*'
                        ? 'nav-link py-4 px-6 active'
                        : 'nav-link py-4 px-6'
                    "
                    data-tab="1"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="true"
                  >
                    <span class="symbol symbol-35">
                      Chats

                      <small
                        v-if="unreadMessages > 0"
                        class="symbol-badge bg-primary"
                      >
                        <b-badge class="" pill variant="danger"
                          >{{ unreadMessages }}
                        </b-badge>
                      </small>
                    </span>
                  </a>
                </router-link>
              </li>
            </b-navbar-nav>
          </b-collapse>
          <div id="topbar">
            <TOPBAR />
          </div>
        </b-navbar>
      </div>
    </div>
    <div class="header-bottom">
      <div class="container mt-6">
        <BREADCRUMB />
      </div>
    </div>
  </div>
  <HEADERADMIN v-else />
</template>

<style lang="scss">
.header-mobile-fixed .wrapper {
  padding-top: 0%;
}

#topbar {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
}
</style>

<script>
var _ = require("lodash");

import { mapGetters } from "vuex";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import TOPBAR from "@/components/header/topbar.vue";
import { SHOW_PROFILE } from "@/services/store/profile.module.js";
import { LIST_VACATIONS } from "@/services/store/holiday.module.js";
import BREADCRUMB from "@/components/menus/breadcrumb";

import HEADERADMIN from "@/components/header/headerAdmin";

export default {
  name: "Header",
  components: {
    TOPBAR,
    BREADCRUMB,
    HEADERADMIN
  },
  data() {
    return {
      tabIndex: 0,
      roles: [1, 2]
    };
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUser",
      "getVacation",
      "getListChats"
    ]),
    unreadMessages() {
      let user = this.currentUser;
      let chats = [];
      let read = 0;
      this.getListChats.forEach(chat => {
        let c = _.find(chat.relationships.chat_user, function(cu) {
          if (cu.pivot.user_id == user.id && cu.pivot.viewed == 0) {
            return cu;
          }
        });
        if (c) {
          chats.push(chat);
          read = read + c.pivot.unread_messages;
        }
        // c ?  : "";
      });
      return read;
    },
    permissions() {
      let result = false;
      if (this.roles.indexOf(this.currentUser?.attributes?.rol_id) != -1) {
        result = true;
      }
      return result;
    },
    isAdmin() {
      return this.currentUser.attributes.rol_id == 4;
    },
    chatId() {
      return btoa(1); // por defecto (chat general)
    },
    havePermission() {
      return this.currentUser.attributes.permission_add ? true : false;
    },
    show() {
      let check = true;
      if (this.$route.name === `showTasksUser`) {
        check = false;
      }
      return check;
    },
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_VACATIONS);
    this.$store.dispatch(SHOW_PROFILE, this.currentUser.id);
  },
  mounted() {
    KTLayoutHeader.init(
      this.$refs["kt_header"],
      this.$refs["kt_header_mobile"]
    );
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_navs"]
    );
  },
  methods: {
    showTasksUser() {
      const payload = {
        userId: this.currentUser.id
      };
      this.$store
        .dispatch("listTasksUser", payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$router.push({
              name: "showTasksUser",
              params: {
                username: this.currentUser.attributes.name
              }
            });
          }
        })
        .catch(e => console.error({ e }));
    }
  }
};
</script>
