<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <div class="d-flex flex-column text-right mr-3">
        <span
          class="text-white opacity-50 font-weight-bold font-size-sm d-none d-md-inline"
          >{{ _getNameUser(this.currentUser.attributes.name) }}</span
        >
        <span
          class="text-white font-weight-bolder font-size-sm d-none d-md-inline"
          >{{ this.currentUser.attributes.rol }}</span
        >
      </div>
      <span class="symbol symbol-35">
        <span
          class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"
          >{{ _getName(this.currentUser.attributes.name) }}</span
        >
      </span>
    </div>
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">Perfil</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ this.currentUser.attributes.name }}
            </a>
            <div class="text-muted mt-1">
              {{ this.currentUser.attributes.rol }}
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ this.currentUser.attributes.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="showModal">
              Finalizar
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Swal from "sweetalert2";
export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters(["currentUser"]),
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    routeProfile() {
      return "/empleados/" + this.currentUser.id;
    }
  },
  mounted() {
    if (typeof this.currentUser.attributes === "undefined") {
      this.$router.push({ name: "login" });
    }
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    _getNameUser(data) {
      let names = data.split(" ");
      return names[0];
    },
    _getNameDirectShow(data) {
      let names = data.name.split("-");
      return this.currentUser.id === data.created_by ? names[0] : names[1];
    },
    _getName(name) {
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },
    handleLogout() {
      const activity_type_id = this.ENDWORK;
      this.$store.dispatch(LOGOUT, activity_type_id).then(() => {
        Swal.close();

        this.$router.replace({ name: "login" });
        this.$socket.emit("LOGOUT_APP");
        this.sockets.unsubscribe("MESSAGE_SEND");
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    showModal() {
      Swal.fire({
        title: "<strong>Cerrar sesión</strong>",
        icon: "info",
        html: "Termino su jornada laboral",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> No'
      }).then(result => {
        if (result.isConfirmed) {
          this.handleLogout();
        } else {
          Swal.close();
        }
      });
    }
  }
};
</script>
