<template>
  <table class="table table-borderless table-vertical-center">
    <thead>
      <tr>
        <th class="p-0" style="width: 20px"></th>
        <th class="p-0" style="min-width: 40px"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="py-5 pl-0">
          <div class="symbol symbol-50 symbol-light mr-2">
            <span class="symbol-label">
              <img
                :src="
                  formation.attributes.type == 'application/pdf'
                    ? 'media/svg/shapes/pdf.svg'
                    : formation.attributes.type == 'test'
                    ? 'media/svg/shapes/test.svg'
                    : 'media/svg/shapes/video.svg'
                "
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </td>
        <td class="pl-0">
          <a
            @click="show"
            class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
            style="cursor:pointer;"
            >{{ formation.attributes.name }}</a
          >
          <span class="text-muted font-weight-bold d-block">
            {{ formation.attributes.created_at }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import { CHANGE_STATUS_FORMATION } from "@/services/store/formation.module";
export default {
  props: ["formation"],
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      for: this.formation,
      background_color: `${
        !this.formation.attributes.active ? "#70b7ff" : "#ffffff"
      }`
    };
  },
  components: {},
  methods: {
    show() {
      this.$emit("show-formation", this.formation);
    },
    active() {
      this.$store
        .dispatch(CHANGE_STATUS_FORMATION, this.formation.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.for = response.data.data;
          }
        })
        .catch(e => console.error(e));
    }
  }
};
</script>
