import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

// action types
export const LIST_EMPLOYEES = "listEmployees";
export const LIST_CUSTOMERS = "listCustomers";
export const LIST_TASKS_USER = "listTasksUser";
export const LIST_ALL_TASKS = "listAllTasks";
export const LIST_LOG_TASKS = "listLogTasks";
export const STORE_TASK = "storeTask";
export const SHOW_TASK = "showTask";
export const CHANGE_STATUS = "changeStatus";
export const STORE_COMMENT = "storeComment";
export const PURGE_TASK = "purgeTask";
export const STORE_ATTACHMENTS = "storeAttachments";
export const UPDATE_TASK = "updateTask";
export const DELETE_ATTACHMENT = "deleteAttachment";
export const DELETE_TASK = "deleteTask";
export const GET_ALL_CUSTOMERS = "getAllCustomers";
// mutation types
export const SET_TASKS = "setTasks ";
export const SET_TASK = "setTask";
export const SET_LOG_TASKS = "setLogTasks";
export const SET_NEW_TASK = "setNewTask";
export const SET_EMPLOYEES = "setEmployees";
export const SET_CUSTOMERS = "setCustomers";
export const SET_ERROR = "setError";
export const ADD_TASK = "addTask";
export const ADD_COMMENT = "addComment";
export const SET_TASK_USER = "setTaskUser";
export const SET_DEPARTMENT_ID = "setDepartmentID";
export const REMOVE_TASK = "removeTask";
export const PURGE_TASKS = "purgeTasks";

const state = {
  tasks: [],
  task: "",
  departmentID: "",
  employees: [],
  logs_tasks: [],
  tmpTask: {},
  customers: []
};

const getters = {
  getTasks(state) {
    return state.tasks;
  },
  getTask(state) {
    return state.task;
  },
  getLogTasks(state) {
    return state.logs_tasks;
  },
  getEmployees(state) {
    return state.employees;
  },
  getUserId() {
    return state.USERID;
  },
  getTmpTask() {
    return state.tmpTask;
  },
  getDepartmentID() {
    return state.departmentID;
  },
  getAllCustomers() {
    return state.customers;
  }
};

const actions = {
  [LIST_EMPLOYEES](context, department) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/tasks/employees/${department}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            let employees = _.map(response.data.employees, e => {
              return {
                id: e.id,
                name: e.attributes.name
              };
            });
            let tasks = response.data.tasks;
            context.commit(SET_EMPLOYEES, employees);
            context.commit(SET_TASKS, tasks);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_CUSTOMERS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/tasks/all/customers`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_CUSTOMERS, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_TASKS_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/tasks/${payload.userId}/user`, { params: payload })
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_TASKS, response.data.tasks);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_ALL_TASKS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/tasks/all/users`, { params: payload })
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_TASKS, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_LOG_TASKS](context, id = 0) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/tasks/logs/${id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_LOG_TASKS, response.data.logs);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_TASK](context, payload) {
    ApiService.setHeader();
    payload.departmentID = sessionStorage.getItem("departmentID");
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/tasks`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(ADD_TASK, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_TASK](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/tasks/${payload.task_id}`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_TASK, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [CHANGE_STATUS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/tasks/${payload.task_id}/status`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(CHANGE_STATUS, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_TASK](context, id) {
    context.commit(PURGE_TASK);
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/tasks/${id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_TASK, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_COMMENT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/tasks/${payload.task_id}/comment`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_TASK, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_ATTACHMENTS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/tasks/${payload.task_id}/attachments`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_TASK, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_TASK](context, id) {
    ApiService.setHeader();
    const params = {
      department: context.state.departmentID
    };
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/tasks/${id}`, params)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(REMOVE_TASK, response.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_ATTACHMENT](context, id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/tasks/${id}/attachment`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_TASK, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [PURGE_TASK](context) {
    context.commit(PURGE_TASK);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_DEPARTMENT_ID](state, id) {
    state.departmentID = id;
  },
  [SET_EMPLOYEES](state, employees) {
    state.employees = employees;
  },
  [SET_CUSTOMERS](state, customers) {
    if (customers.length > 0) {
      let arrayCustomers = _.map(customers, e => {
        return {
          id: e.id,
          name: e.attributes.name,
          dni_nie: e.attributes.dni_nie
        };
      });
      state.customers = arrayCustomers;
    }
  },
  [SET_TASKS](state, tasks) {
    state.tasks = tasks;
  },
  [SET_TASK](state, task) {
    state.task = task;
  },
  [SET_LOG_TASKS](state, logs) {
    state.logs_tasks = logs;
  },
  [UPDATE_TASK](state, task) {
    state.task = task;
    for (var idx in state.tasks) {
      if (state.tasks[idx].groupID == task.attributes.status_id) {
        for (var idx2 in state.tasks[idx]) {
          if (state.tasks[idx][idx2].id == task.id) {
            state.tasks[idx][idx2] == task;
          }
        }
      }
    }
  },
  [ADD_TASK](state, task) {
    state.tasks = [...state.tasks, task];
  },
  [CHANGE_STATUS](state, task) {
    let found = _.find(state.tasks, { id: task.id });
    if (found) {
      found.status_id = task.status_id;
    }
  },
  [PURGE_TASKS](state) {
    state.tasks = [];
  },
  [PURGE_TASK](state) {
    state.task = "";
  },
  [REMOVE_TASK](state, task) {
    var tasks = state.tasks;
    for (var i in tasks) {
      if (tasks[i].id == task.id) {
        const index = tasks.indexOf(tasks[i]);
        tasks.splice(index, 1);
      }
    }
    state.tasks = tasks;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
