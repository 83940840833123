<template>
  <b-container>
    <div>
      <template>
        <div class="col-12">
          <b-card
            title="Mi Vacación"
            class="card card-custom card-stretch gutter-b font-weight-bold text-primary"
          >
            <b-row>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Nombre: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <label label-cols-sm="7">{{ name }}</label>
                </b-form-group>
                <b-form-group
                  label="Dias disponible de vacación: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <label label-cols-sm="7">{{ available_days }} </label>
                </b-form-group>

                <b-form-group
                  label="Fechas Solicitadas: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <!-- "YYYY-MM-DD hh:mm:ss -->
                  <label label-cols-sm="7"
                    >{{ moment(date_request_init).format("YYYY-MM-DD") }}/{{
                      moment(date_request_end).format("YYYY-MM-DD")
                    }}
                    - ({{ days_request }})
                  </label>
                </b-form-group>

                <!-- para controlar permisos -->
                <div>
                  <div
                    v-if="
                      this.vacation.relationships.status.attributes.name ===
                        'aprobado'
                    "
                  >
                    <b-form-group
                      label="Fechas Aprobadas:"
                      label-for="initial-sort-select"
                      label-cols-sm="6"
                      label-align-sm="light"
                      label-size="sm"
                      class="mb-0"
                      label-class="font-weight-bold text-primary"
                    >
                      <label label-cols-sm="7"
                        >{{ date_approved_start }}/{{ date_approved_over }} -
                        ({{ days_approved }})
                      </label>
                    </b-form-group>
                  </div>
                  <div
                    v-if="
                      this.vacation.relationships.status.attributes.name ===
                        'aprobado'
                    "
                  >
                    <b-form-group
                      label="Reemplazo: "
                      label-for="initial-sort-select"
                      label-cols-sm="6"
                      label-align-sm="light"
                      label-size="sm"
                      class="mb-0"
                      label-class="font-weight-bold text-primary"
                    >
                      <label label-cols-sm="7">{{ replacement }}</label>
                    </b-form-group>
                  </div>

                  <b-form-group
                    label="Estado: "
                    label-for="initial-sort-select"
                    label-cols-sm="6"
                    label-align-sm="light"
                    label-size="sm"
                    class="mb-0"
                    label-class="font-weight-bold text-primary"
                  >
                    <label label-cols-sm="7">{{ status }}</label>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </template>
      <template>
        <div class="container-fluid">
          <form class="bg-white shadow-md rounded px-8 pt-6 pb-8">
            <div class="mb-4">
              <DatePicker
                color="red"
                is-success
                is-expanded
                :from-date="new Date(date_request_init)"
                :attributes="attrs"
                :value="null"
              >
                <!-- <template #day-popover>
                  <div v-for="(d, i) in holiday" :key="i">
                    {{ d.holiday }}
                  </div>
                </template> -->
              </DatePicker>
            </div>
          </form>
        </div>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="$emit('close-modal-show', false)"
        >
          Cerrar
        </b-button>
      </template>
    </div>
  </b-container>
</template>
<script>
//var _ = require("lodash");
import { mapGetters } from "vuex";
var _ = require("lodash");
var moment = require("moment");
import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters(["currentUser", "getVacation", "getProfile"]),

    name() {
      return this.currentUser.attributes.name;
    },
    available_days() {
      return this.vacation.relationships.user.attributes
        .available_days_vacations;
    },
    days_request() {
      let fechas = [];
      this.holiday.forEach(value => {
        fechas.push(moment(value.date_calendar).format("YYYY, M, DD"));
      });
      var from = moment(this.vacation.attributes.date_init),
        to = moment(this.vacation.attributes.date_end),
        days = 0;
      while (!from.isAfter(to)) {
        var aux = _.find(fechas, function(e) {
          return e == moment(from).format("YYYY, M, DD");
        });
        // Si no es sabado ni domingo
        if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
          days++;
        }
        from.add(1, "days");
      }
      return days;
      //return "";
    },
    date_request_end() {
      return this.vacation.attributes.date_end;
    },
    date_request_init() {
      return this.vacation.attributes.date_init;
    },
    date_from() {
      return moment(this.vacation.attributes.date_init).format("YYYY-MM-DD");
    },

    days_approved() {
      if (this.vacation.attributes.date_start != null) {
        let fechas = [];
        this.holiday.forEach(value => {
          fechas.push(moment(value.date_calendar).format("YYYY, M, DD"));
        });
        var from = moment(this.vacation.attributes.date_start),
          to = moment(this.vacation.attributes.date_over),
          days = 0;

        while (!from.isAfter(to)) {
          var aux = _.find(fechas, function(e) {
            return e == moment(from).format("YYYY, M, DD");
          });
          // Si no es sabado ni domingo
          if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
            days++;
          }
          from.add(1, "days");
        }
        return days;
      } else {
        return "";
      }
    },
    date_approved_start() {
      if (this.vacation.attributes.date_start != null) {
        return moment(this.vacation.attributes.date_start).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    date_approved_over() {
      if (this.vacation.attributes.date_over != null) {
        return moment(this.vacation.attributes.date_over).format("YYYY-MM-DD");
      } else {
        return " ";
      }
    },
    status() {
      if (this.vacation.attributes.status_id != null) {
        return this.vacation.relationships.status.attributes.name;
      } else {
        return "";
      }
    },
    replacement() {
      if (this.vacation.attributes.replacement_id != null) {
        return this.vacation.relationships.replacement.attributes.name;
      } else {
        return "";
      }
    }
  },
  props: ["vacation", "holiday", "dateHoly", "dayHoly"],
  data() {
    return {
      // vacation: this.$route.params.vacation,
      attrs: [
        {
          highlight: {
            start: { fillMode: "outline" },
            base: { color: "blue", fillMode: "solid" },
            end: { fillMode: "outline" }
          },
          dates: {
            start: moment().format("YYYY, MM, DD"),
            end: moment().format("YYYY, MM, DD")
          }
        },
        {
          highlight: {
            start: { fillMode: "outline" },
            base: { color: "green", fillMode: "solid" },
            end: { fillMode: "outline" }
          },
          dates: {
            start: moment().format("YYYY, MM, DD"),
            end: moment().format("YYYY, MM, DD")
          }
        },
        {
          //description: "Take Noah to basketball practice.",
          key: "today",
          highlight: {
            color: "purple",
            fillMode: "solid",
            contentClass: "italic"
          },
          dates: this.dateHoly,
          //popover: true
          popover: {
            label: "Feriado"
          }
        }
      ]
    };
  },
  created() {
    //if (this.vacation.attributes.date_start != null) {
    this.attrs[0].dates.start = moment(
      this.vacation.attributes.date_init
    ).format("YYYY, MM, DD");
    this.attrs[0].dates.end = moment(this.vacation.attributes.date_end).format(
      "YYYY, MM, DD"
    );
    //}
    if (this.vacation.attributes.date_start != null) {
      this.attrs[1].dates.start = moment(
        this.vacation.attributes.date_start
      ).format("YYYY, MM, DD");
      this.attrs[1].dates.end = moment(
        this.vacation.attributes.date_over
      ).format("YYYY, MM, DD");
    } else {
      return "";
    }
  }
};
</script>
