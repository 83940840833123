<template>
  <b-card>
    <b-row>
      <b-col cols>
        <div class="col-xl-12 col-xxl-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Ordenar"
                label-for="sort-by-select"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sort-by-select"
                    v-model="sortBy"
                    :options="sortOptions"
                    :aria-describedby="ariaDescribedby"
                    class="w-75"
                  >
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>

                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    :aria-describedby="ariaDescribedby"
                    size="sm"
                    class="w-25"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="6" class="my-1">
              <b-form-group
                label="Initial sort"
                label-for="initial-sort-select"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="initial-sort-select"
                  v-model="sortDirection"
                  :options="['asc', 'desc', 'last']"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filtrar"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="6" class="my-1">
              <b-form-group
                v-model="sortDirection"
                label="Filtro activado"
                description="Deje todo sin marcar para filtrar todos los datos"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  v-model="filterOn"
                  :aria-describedby="ariaDescribedby"
                  class="mt-1"
                >
                  <b-form-checkbox value="name">Name</b-form-checkbox>
                  <b-form-checkbox value="date_init">Incio</b-form-checkbox>
                  <b-form-checkbox value="date_end"
                    >Finalización</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>

            <b-col sm="5" md="6" class="my-1">
              <b-form-group
                label="Por pagina"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>

          <!-- Main table element -->
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(name)="row">
              {{ row.value }}
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
var _ = require("lodash");
export default {
  props: ["getFormation"],
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Nombre del usuario",
          sortable: true,
          class: "text-center"
        },
        {
          key: "date_init",
          label: "Hora que empezo el test",
          sortable: true,
          class: "text-center"
        },
        {
          key: "date_end",
          label: "Hora que finalizo el test",
          sortable: true,
          class: "text-center"
        },
        {
          key: "time_total",
          label: "Tiempo total de visualización (MIN)",
          sortable: true,
          class: "text-center"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [15, 20, 50, { value: 100, text: "Ver todos" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters([
      "getBreadcrumbSegment",
      "currentUser",
      "getEmployeeProfile"
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    logs() {
      if (this.getFormation.relationships.logs) {
        return this.getFormation.relationships.logs.map(l => {
          if (l.user_id != this.currentUser) return l.attributes;
        });
      } else {
        return [];
      }
    },
    items() {
      let items = [];

      if (this.logs.length) {
        let log = _.orderBy(this.logs, ["date_init"], ["desc"]);

        log = _.filter(this.logs, ["date_end"]);
        log = _.filter(this.logs, function(l) {
          return l.date_end;
        });

        for (var idx in log) {
          var startTime = moment(log[idx].date_init);
          var endTime = moment(log[idx].date_end);
          if (typeof endTime !== [undefined, NaN]) {
            var time = endTime.diff(startTime, "minutes");
          }
          if (log[idx].user_id == this.getEmployeeProfile.id) {
            items.push({
              name:
                log[idx].user_id == this.currentUser.id ? `Yo` : log[idx].user,
              date_init: log[idx].date_init,
              date_end: log[idx].date_end,
              time_total: typeof time !== undefined ? time : ""
            });
          }
        }
      }

      return items;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>
