<template>
  <b-container>
    <b-row v-if="this.$route.name === `showStatusVacation`">
      <!-- <p v-if="muestra">Tienes un solicitudad de vacacion {{ res.id }}</p> -->
      <template>
        <div class="col-12">
          <b-card
            title="Solicitud de Vacación"
            class="card card-custom card-stretch gutter-b"
          >
            <b-row>
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Nombre: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <label label-cols-sm="7">{{ name }}</label>
                </b-form-group>
                <b-form-group
                  label="Dias disponible de vacación: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <label label-cols-sm="7"
                    >{{ available_days }}/{{ operation }}</label
                  >
                </b-form-group>

                <b-form-group
                  label="Fechas Solicitadas: "
                  label-for="initial-sort-select"
                  label-cols-sm="6"
                  label-align-sm="light"
                  label-size="sm"
                  class="mb-0"
                  label-class="font-weight-bold text-primary"
                >
                  <label label-cols-sm="7"
                    >{{ moment(initRequest).format("YYYY-MM-DD ") }}/{{
                      moment(endRequest).format("YYYY-MM-DD ")
                    }}-({{ dayRequest }})
                  </label>
                </b-form-group>

                <!-- para controlar permisos -->
                <div
                  v-if="
                    this.currentUser.attributes.rol_id === 2 && havePermission
                  "
                >
                  <b-form-group
                    label="Fechas Aprobadas:"
                    label-for="initial-sort-select"
                    label-cols-sm="6"
                    label-align-sm="light"
                    label-size="sm"
                    class="mb-0"
                    label-class="font-weight-bold text-primary"
                  >
                    <label label-cols-sm="7"
                      >{{
                        range_request.start
                          ? moment(range_request.start).format("YYYY-MM-DD ")
                          : ""
                      }}/{{
                        range_request.end
                          ? moment(range_request.end).format("YYYY-MM-DD")
                          : ""
                      }}-({{ dayApproved }})
                    </label>
                  </b-form-group>
                  <b-form-group
                    label="Sustituto:"
                    label-for="initial-sort-select"
                    label-cols-sm="6"
                    label-align-sm="light"
                    label-size="sm"
                    class="mb-0"
                    label-class="font-weight-bold text-primary"
                  >
                    <select v-model="replacement" class="custom-select">
                      <option value="">Seleccione un sustituto</option>
                      <option
                        v-for="(e, i) in employees_all"
                        :key="i"
                        :value="e.id"
                      >
                        {{ e.name }}
                      </option>
                    </select>
                  </b-form-group>
                </div>
                <!-- fin de controlar permisos -->
              </b-col>

              <b-col lg="6" md="4" class="col align-self-end">
                <br />
                <br />
                <br />
              </b-col>
            </b-row>
          </b-card>
          <br />
        </div>
      </template>

      <!-- <CALENDARY /> -->
      <template>
        <div class="container-fluid">
          <form class="bg-primary shadow-md rounded px-8 pt-6 pb-8">
            <div class="mb-4">
              <DatePicker
                color="green"
                fillMode="solid"
                is-success
                is-expanded
                is-range
                v-model="range_request"
                :from-date="new Date(initRequest)"
                :attributes="attrs"
              >
              </DatePicker>

              <!-- <div v-if="this.currentUser.attributes.rol_id=== 2 && havePermission"> -->
              <b-button
                ref="btnSubmitB"
                @click="aprobar(10, 'btnSubmitB')"
                variant="success"
                size="sm"
                class="float-right ml-5 mt-1"
              >
                Rechazar
              </b-button>
              <b-button
                ref="btnSubmitA"
                variant="success"
                size="sm"
                class="float-right ml-5 mt-1"
                @click="aprobar(9, 'btnSubmitA')"
              >
                Aprobar
              </b-button>
              <!-- </div> -->

              <!-- <b-button
                v-if="
                  (this.currentUser.attributes.rol_id=== 2 && !havePermission) ||
                    this.currentUser.attributes.rol_id=== 1 ||
                    this.currentUser.attributes.rol_id=== 3
                "
                variant="primary"
                size="sm"
                class="float-right ml-5"
                ref="buttonRequest"
                @click="saveUpdateVacation"
              >
                {{ action === "edit" ? "Actualizar" : "Solicitar" }}
              </b-button> -->
            </div>
          </form>
          <br />

          <template>
            <div class="col-12">
              <b-row> </b-row>
            </div>
          </template>
        </div>
      </template>
    </b-row>
  </b-container>
</template>

<script>
var _ = require("lodash");

import { mapGetters } from "vuex";
var moment = require("moment");
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import {
  SHOW_USER_VACATION,
  STATUS_VACATION
} from "@/services/store/holiday.module";
import { LIST_EMPLOYEES_ALL } from "@/services/store/employee.module";
import { LIST_PROFILES } from "@/services/store/profile.module";
import Swal from "sweetalert2";
export default {
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters([
      "getEmployeeProfile",
      "getVacations",
      "currentUser",
      "getProfiles",
      "getEmployeesAll",
      "employeeProfile",
      "getVacation"
    ]),
    pruebaDate() {
      let ini_date = this.getVacations[0].attributes.date_init;
      let fin_date = this.getVacations[0].attributes.date_end;
      return {
        start: ini_date,
        end: fin_date
      };
    },
    employees_all() {
      return _.map(this.getProfiles, e => {
        return { id: e.id, name: e.attributes.name };
      });
    },
    // employees_all() {
    //   return _.map(this.getEmployeesAll, e => {
    //     return { id: e.id, name: e.attributes.name };
    //   });
    // },
    name() {
      return this.getEmployeeProfile.attributes.name;
    },
    id() {
      return this.getVacation.attributes.id;
    },
    havePermission() {
      return this.currentUser.attributes.permission_add ? true : false;
    },
    available_days() {
      return this.getEmployeeProfile.attributes.available_days_vacations;
    },
    initRequest() {
      return this.getVacation.attributes.date_init;
    },
    endRequest() {
      return this.getVacation.attributes.date_end;
    },
    operation() {
      if (this.range_request.start != "" && this.range_request.end != "") {
        return (
          this.getEmployeeProfile.attributes.available_days_vacations -
          this.dayApproved
        );
      } else {
        return "";
      }
    },
    dayRequest() {
      var from = moment(this.getVacation.attributes.date_init),
        to = moment(this.getVacation.attributes.date_end),
        holiday = this.holidaysDate,
        days = 0;

      while (!from.isAfter(to)) {
        var aux = _.find(holiday, function(e) {
          return e == moment(from).format("YYYY, M, DD");
        });
        // Si no es sabado ni domingo
        if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
          days++;
        }
        from.add(1, "days");
      }
      return days;
    },
    dayApproved() {
      if (this.range_request.start != "" && this.range_request.end != "") {
        var from = moment(this.range_request.start, "YYYY-MM-DD"),
          to = moment(this.range_request.end, "YYYY-MM-DD"),
          holiday = this.holidaysDate,
          days = 0;

        while (!from.isAfter(to)) {
          var aux = _.find(holiday, function(e) {
            return e == moment(from).format("YYYY, M, DD");
          });
          // Si no es sabado ni domingo
          if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
            days++;
          }
          from.add(1, "days");
        }
        return days;
      } else {
        return "";
      }
    }
  },

  data() {
    return {
      holidaysDate: this.$route.params.dateHoliday,
      muestra: false,
      res: {},
      //socket: io("localhost:3001"),
      replacement: "",

      attrs: [
        {
          highlight: {
            start: { fillMode: "outline" },
            base: { color: "blue", fillMode: "solid" },
            end: { fillMode: "outline" }
          },
          dates: {
            start: moment().format("YYYY, MM, DD"),
            end: moment().format("YYYY, MM, DD")
          }
        },
        {
          description: "Take Noah to basketball practice.",
          key: "today",
          highlight: {
            color: "purple",
            fillMode: "solid",
            contentClass: "italic"
          },
          dates: this.$route.params.dateHoliday,

          popover: {
            label: "Feriado "
          }
        }
      ],
      action: this.$route.params.action,
      form: {
        id: "",
        profile_id: "",
        days_vacations: "",
        date_init: "",
        date_end: "",
        date_start: "",
        date_over: "",
        status_id: "",
        replacement_id: "",
        created_by: ""
      },
      vacation: this.$route.params.request,
      range_request: {
        start: "",
        end: ""
      },
      range_aproved: {
        start: "",
        end: ""
      },
      masks: {
        input: "YYYY-MM-DD"
      }
      //id: this.$route.params.vacation
    };
  },
  created() {
    this.attrs[0].dates.start = moment(
      this.vacation.attributes.date_init
    ).format("YYYY, MM, DD");
    this.attrs[0].dates.end = moment(this.vacation.attributes.date_end).format(
      "YYYY, MM, DD"
    );

    this.form.days_vacations =
      this.action == "edit" ? this.vacation.attributes.days_vacations : "";
    this.form.replacement_id =
      this.action == "edit" ? this.vacation.attributes.replacement_id : "";

    //para registrar fechas de aprobacion de vacacion
    this.range_request.start =
      this.action == "edit" ? this.vacation.attributes.date_start : "";
    this.range_request.end =
      this.action == "edit" ? this.vacation.attributes.date_over : "";
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
    this.$store.dispatch(LIST_PROFILES);
  },
  mounted() {},
  methods: {
    // ApprovedDays() {
    //   if (this.range_request.start != "" && this.range_request.end != "") {
    //     var from = moment(this.range_request.start, "YYYY-MM-DD"),
    //       to = moment(this.range_request.end, "YYYY-MM-DD"),
    //       days = 0;

    //     while (!from.isAfter(to)) {
    //       // Si no es sabado ni domingo
    //       if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7) {
    //         days++;
    //       }
    //       from.add(1, "days");
    //     }
    //     return days;
    //   } else {
    //     return "";
    //   }
    // },
    ApprovedDays() {
      if (this.range_request.start != "" && this.range_request.end != "") {
        var from = moment(this.range_request.start, "YYYY-MM-DD"),
          to = moment(this.range_request.end, "YYYY-MM-DD"),
          holiday = this.holidaysDate,
          days = 0;

        while (!from.isAfter(to)) {
          var aux = _.find(holiday, function(e) {
            return e == moment(from).format("YYYY, M, DD");
          });
          // Si no es sabado ni domingo
          if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
            days++;
          }
          from.add(1, "days");
        }
        return days;
      } else {
        return "";
      }
    },
    succesAppoved(data) {
      // e.preventDefault();
      this.$socket.emit("SUCCES_APPROVED", data);
      // this.message = "";
    },

    aprobar(status, button) {
      const submitButton = this.$refs[button];

      this.form.id = this.getVacation.id;
      if (status === 9) {
        this.form.days_vacations = this.ApprovedDays();
        this.form.date_start = this.moment(this.range_request.start).format(
          "YYYY-MM-DD hh:mm:ss"
        );
        this.form.date_over = this.moment(this.range_request.end).format(
          "YYYY-MM-DD hh:mm:ss"
        );
        this.form.replacement_id = this.replacement;
        if (!this.form.replacement_id) {
          Swal.fire("Error!", "Debes Seleccionar un Empleado", "error");

          return;
        }
      }
      this.form.status_id = status;

      this.$store
        //nuevo dispach para aprobar o rechazar
        .dispatch(STATUS_VACATION, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.succesAppoved(response.data.data);

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.$store.dispatch(
              SHOW_USER_VACATION,
              response.data.data.attributes.profile_id.id
            );
            Swal.fire(
              "Ok!",
              "La función fue registrada exitosamente",
              "success"
            );
            this.$router.push({
              name: `holidays`
            });
          }
        })
        .catch(e => {
          Swal.fire(
            "",
            "Por favor! Selecione los días que quiere aprobar",
            "error"
          );
          console.log(e);
        });
    }
  }
};
</script>
