<template>
  <div
    bg-variant="danger"
    :style="message.relationships.children.length > 1 ? 'height: 200px;' : ''"
    style="position: relative; overflow-y: auto"
    :class="`message-children-${message.id}`"
    :ref="`message-children-${message.id}`"
  >
    <b-alert
      v-for="(msg, index) in message.relationships.children"
      :key="index"
      show
      :variant="msg.attributes.parent_id ? 'secondary' : 'white'"
    >
      <b-row>
        <b-col>
          <div>
            <h5 class="float-left pt-2">
              {{ msg.relationships.createdBy.name }}
            </h5>
            <a
              v-b-tooltip.hover="{ variant: 'info' }"
              :title="
                `${moment(msg.attributes.created_at).format(
                  'dddd - DD MMMM YYYY'
                )} a las ${moment(msg.attributes.created_at).format(
                  'h:mm:ss a'
                )} `
              "
              class="float-left pl-2 pt-2"
            >
              <strong>{{
                moment(msg.attributes.created_at)
                  .startOf("minutes")
                  .fromNow()
              }}</strong>
            </a>
          </div>
        </b-col>
        <b-col
          md="12"
          :class="
            !msg.attributes.state ||
            (msg.attributes.message == '' &&
              msg.relationships.attachments.length == 0)
              ? 'text-dark'
              : ''
          "
        >
          <div v-if="msg.attributes.type === 'text'">
            {{
              msg.attributes.state
                ? msg.attributes.message == "" &&
                  msg.relationships.attachments.length == 0
                  ? "Archivos Eliminado"
                  : msg.attributes.message
                : "Mensaje eliminado"
            }}
          </div>
          <template v-if="msg.attributes.state">
            <WIDGETLISTATTACHMENT :msg="msg" />
            <div class="accordion" role="tablist">
              <b-badge
                v-if="msg.attributes.created_by === currentUser.id"
                @click="deleteMessage(msg)"
                class="mt-2"
                pill
                style="cursor: pointer"
                variant="outline-primary"
              >
                <strong>Eliminar</strong></b-badge
              >
            </div>
          </template>
        </b-col>
      </b-row>
      <b-collapse
        accordion="my-accordion1"
        role="tabpanel"
        :id="`accordion-edit-${message.id}`"
        class="mt-2"
        close
      >
        <FORMADDMESSAGE :msg="msg" :action="'edit'" />
      </b-collapse>
    </b-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FORMADDMESSAGE from "@/components/chats-module/form/form-add-message";
import WIDGETLISTATTACHMENT from "@/components/chats-module/attachment/widget-attachment";
const Swal = require("sweetalert2");
import { DELETE_MESSAGE } from "@/services/store/chat.module";

export default {
  components: {
    FORMADDMESSAGE,
    WIDGETLISTATTACHMENT
  },
  comments: {
    Swal
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  props: ["message"],
  data() {
    return {
      action: "create",
      msgA: {}
    };
  },
  methods: {
    setMessage: function(msg) {
      this.msgA = msg;
    },

    getName(name) {
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },
    deleteMessage(message) {
      Swal.fire({
        title: "¿Estas seguro de eliminar el mensaje?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, eliminar mensaje"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_MESSAGE, message)
            .then(response => {
              Swal.fire(
                "Exito!",
                "El mensaje fue eliminado con exito.",
                "success"
              );
              this.$socket.emit("DELETE_MESSAGE", response.data.data);
            })
            .catch(e => {
              console.error(e);
              Swal.fire("Error!", "El mensaje no fue eliminado.", "warning");
            });
        }
      });
    }
  },
  updated() {
    let children = document.querySelector(
      `.message-children-${this.message.id}`
    );

    children.scrollTop = children.scrollHeight;
  }
};
</script>
