<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title"><span>1</span> Información</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>2</span> Preguntas</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title"><span>3</span> Resumen</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
          <div class="col-xl-12 col-md-12">
            <div
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h4 class="mb-4 font-weight-bold text-dark">
                Formación: {{ this.getCurrentlyFormationSelected.name }}
              </h4>

              <b-form-group
                id="input-group-1"
                label="Titulo del test (*)"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  required
                  placeholder="Titulo del test"
                />

                <b-form-invalid-feedback :state="Boolean(form.name)">
                  Se debe indicar un titulo para el test
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Descripción de la Formación"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea-no-resize"
                  placeholder="Descripción"
                  rows="3"
                  no-resize
                  v-model="form.description"
                />
              </b-form-group>

              <b-form-group
                label-for="input-3"
                label="Departamentos (*)"
                v-if="this.action != `edit`"
              >
                <multiselect
                  v-model="form.departments"
                  :options="departments"
                  :multiple="true"
                  :group-select="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Seleccione un(os) departamento(s)"
                  label="text"
                  track-by="text"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} seleccionados</span
                    ></template
                  >
                </multiselect>

                <b-form-invalid-feedback :state="Boolean(form.departments)">
                  Se debe indicar al menos un departamento
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="Seleccione que tipo de usuario puede ver la formación"
                id="input-group-4"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  v-model="form.roles"
                  id="checkboxes-4"
                  :aria-describedby="ariaDescribedby"
                >
                  <b-form-checkbox
                    v-for="rol in roles"
                    :key="rol.value"
                    :value="rol.value"
                    >{{ rol.text }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
                <p class="mt-2" style="color: red">
                  Por defecto, todos los usuarios pueden ver la formación
                </p>
              </b-form-group>
            </div>
            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Preguntas
              </h4>
              <b-row>
                <b-col cols xs="12" sm="2" md="2" lg="2" xl="2" class="mb-4">
                  <button class="btn btn-secondary button mt-0" @click="add">
                    <b-icon icon="plus-circle" scale="1"></b-icon> Pregunta
                  </button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols>
                  <draggable
                    tag="ul"
                    :list="form.questions"
                    class="list-group"
                    handle=".handle"
                  >
                    <li
                      class="list-group-item"
                      v-for="(element, idx) in form.questions"
                      :key="idx"
                    >
                      <b class="mr-2">Pregunta {{ idx + 1 }}</b>
                      <i class="fa fa-align-justify handle"></i>
                      <input
                        type="text"
                        class="form-control mb-4"
                        v-model="element.text"
                      />
                      <b-icon
                        icon="plus-circle"
                        scale="2 "
                        class="ml-6"
                        variant="primary"
                        style="cursor:pointer"
                        @click="addAnswer(idx)"
                      ></b-icon>
                      <b-icon
                        icon="x-circle"
                        scale="2"
                        variant="danger"
                        class="ml-6"
                        style="cursor:pointer"
                        v-if="idx > 0"
                        @click="removeAt(idx)"
                      ></b-icon>

                      <br />
                      <draggable
                        tag="ul"
                        :list="element.answers"
                        class="list-group"
                        handle=".handle"
                      >
                        <li
                          class="list-group-item"
                          v-for="(answer, i) in element.answers"
                          :key="i"
                        >
                          <b class="mr-2">Respuesta {{ i + 1 }}</b>
                          <i class="fa fa-align-justify handle"></i>
                          <input
                            type="text"
                            class="form-control"
                            v-model="answer.text"
                          />
                          <b-icon
                            icon="x-circle"
                            scale="2"
                            variant="danger"
                            class="ml-6"
                            style="cursor:pointer"
                            v-if="i > 0"
                            @click="removeAnswer(idx, i)"
                          ></b-icon>
                          <b-button
                            :variant="
                              answer.correct ? 'primary' : 'outline-primary'
                            "
                            class="ml-6"
                            size="sm"
                            @click="correctAnswer(idx, i)"
                          >
                            <span v-if="answer.correct">VERDADERA</span
                            ><span v-if="answer.correct == false">FALSA</span>
                          </b-button>
                        </li>
                      </draggable>
                    </li>
                  </draggable>
                </b-col>
              </b-row>
            </div>
            <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">
                Resumen del Test
              </h4>

              <div class="border-bottom mb-5 pb-5">
                <div class="font-weight-bold mb-3">
                  Titulo
                </div>
                <div class="line-height-md">
                  {{ this.form.name }}
                </div>
              </div>

              <div class="border-bottom mb-5 pb-5">
                <div class="font-weight-bold mb-3">
                  Departamentos que pueden visualizar el test
                </div>

                <div class="line-height-md">
                  <b-badge
                    variant="light"
                    v-for="(element, idx) in this.form.departments"
                    :key="idx"
                  >
                    {{ element.text }}
                  </b-badge>
                </div>
              </div>
              <div class="border-bottom mb-5 pb-5">
                <div class="font-weight-bold mb-3">
                  Descripción
                </div>
                <div class="line-height-md">
                  {{ this.form.description }}
                </div>
              </div>

              <div class="border-bottom mb-5 pb-5">
                <div class="font-weight-bold mb-3">
                  Preguntas
                </div>
                <b-row>
                  <b-col cols>
                    <draggable
                      tag="ul"
                      :list="form.questions"
                      class="list-group"
                      handle=".handle"
                    >
                      <li
                        class="list-group-item"
                        v-for="(element, idx) in form.questions"
                        :key="idx"
                      >
                        <i class="fa fa-align-justify handle"></i>
                        <input
                          type="text"
                          class="form-control mb-4"
                          disabled
                          v-model="element.text"
                        />

                        <br />
                        <draggable
                          tag="ul"
                          :list="element.answers"
                          class="list-group"
                          handle=".handle"
                        >
                          <b-list-group-item
                            v-for="(answer, i) in element.answers"
                            :key="i"
                            :variant="answer.correct ? `primary` : `light`"
                          >
                            <i class="fa fa-align-justify handle"></i>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              v-model="answer.text"
                            />

                            <b-icon
                              icon="check2-circle"
                              scale="2"
                              variant="primary"
                              class="ml-6"
                              v-if="answer.correct"
                            ></b-icon>
                          </b-list-group-item>
                        </draggable>
                      </li>
                    </draggable>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <button
                  class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-prev"
                >
                  Atras
                </button>
              </div>
              <div>
                <button
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                  ref="btnSubmit"
                  @click="onSubmit"
                >
                  Guardar
                </button>
                <button
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-next"
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.button {
  margin-top: 35px;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 70%;
}
.text {
  margin: 20px;
}
</style>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { mapGetters } from "vuex";
import KTWizard from "@/assets/js/components/wizard";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";

import draggable from "vuedraggable";

var _ = require("lodash");
export default {
  // props: ["action"],
  props: {
    segmentID: Number,
    action: String
  },
  data() {
    return {
      attachment: null,
      form: {
        id: this.action === `edit` ? this.formation.id : "",
        name: this.action === `edit` ? this.formation.attributes.name : "",
        folder: {},
        segment: {},
        roles:
          this.action === `edit`
            ? this.formation.attributes.roles_have_permission_to_view.split(",")
            : [1, 2, 3],
        description:
          this.action === `edit` ? this.formation.attributes.description : "",
        departments:
          this.action === `edit`
            ? _.map(this.formation.relationships.departments, d => {
                return { value: d.id, text: d.attributes.name };
              })
            : this.departments,
        type: "test",
        questions: [
          {
            text: "",
            answers: [{ text: "", correct: false }]
          }
        ]
      },
      typeExcluded: ["texto", "video"],
      codeStatus: [200, 201],
      errors: ""
    };
  },
  components: {
    draggable
  },
  computed: {
    ...mapGetters([
      "getDepartments",
      "getFolders",
      "getSegments",
      "getCurrentlyFormationSelected",
      "getRoles"
    ]),
    segments() {
      let arr = [];
      if (this.getSegments.length > 0) {
        for (let i = 0; i < this.getSegments.length; i++) {
          let exist = this.typeExcluded.indexOf(
            this.getSegments[i].attributes.name
          );
          if (exist < 0) {
            arr.push(this.getSegments[i]);
          }
        }
      }
      return arr;
    },
    departments() {
      let arrayDepartments = [
        {
          value: 0,
          text: "Todos"
        }
      ];
      const listDepartments = this.getDepartments;
      for (let i = 0; i < listDepartments.length; i++) {
        arrayDepartments.push({
          value: listDepartments[i].id,
          text: listDepartments[i].attributes.name
        });
      }
      return arrayDepartments;
    },
    roles() {
      let arr = [];
      if (this.getRoles.length > 0) {
        for (let i = 0; i < this.getRoles.length; i++) {
          if (this.getRoles[i].attributes.visible) {
            arr.push({
              text: this.getRoles[i].attributes.name,
              value: this.getRoles[i].id
            });
          }
        }
      }
      return arr;
    }
  },
  methods: {
    getSegmentAndFormation() {
      const foundSegment = this.segments.find(s => s.id == this.segmentID);
      this.form.segment = {
        id: foundSegment.id,
        extensions: foundSegment.attributes.extensions,
        mime: foundSegment.attributes.mime
      };
      this.extensions = foundSegment.attributes.extensions;
      this.form.folder.value = this.getCurrentlyFormationSelected.id;
    },
    removeAt(idx) {
      this.form.questions.splice(idx, 1);
    },
    add() {
      this.form.questions.push({
        text: "",
        answers: [{ text: "", correct: false }]
      });
    },
    removeAnswer(idx, i) {
      this.form.questions[idx].answers.splice(i, 1);
    },
    addAnswer(idx) {
      this.form.questions[idx].answers.push({ text: "", correct: false });
    },
    correctAnswer(idx, i) {
      let answers = this.form.questions[idx].answers;
      if (answers[i].correct == true) {
        answers[i].correct = !answers[i].correct;
      } else {
        // Object.keys(answers).forEach(key => (answers[key].correct = false));
        answers[i].correct = !answers[i].correct;
      }
    },
    async onSubmit() {
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (!this.form.name) {
        Swal.fire("", "Se debe ingresar un título", "error");
        return;
      }

      if (!this.form.folder) {
        Swal.fire("", "Se debe seleccionar una formación", "error");
        return;
      }

      if (!this.form.departments) {
        Swal.fire("", "Se debe seleccionar al menos un departamento", "error");
        return;
      }

      // Verifica si existe al menos un valor con 0 (Todos los departamentos)
      if (_.some(this.form.departments, ["value", 0])) {
        this.form.departments = _.filter(this.departments, d => {
          return d.value != 0;
        });
      }

      if (this.form.roles.length == 0) {
        this.form.roles = [1, 2, 3];
      }
      await this.store();
    },
    async store() {
      this.load = true;
      this.form.path = `${this.form.folder.text}/${this.form.name}`;

      this.form.segment = _.find(this.getSegments, s => {
        return s.attributes.name === `test`;
      });

      this.$store
        .dispatch("storeFormation", this.form)
        .then(response => {
          if (this.codeStatus.indexOf(response.status) != -1) {
            Swal.fire("Ok!", "Se creo el test exitosamente.", "success");
            this.$emit("store-formation", "modal-test");
          }
        })
        .catch(e => {
          console.error(e.message);
          Swal.fire("", "Por favor! intente de nuevo", "error");
        });
    }
  },
  beforeCreate() {
    this.$store.dispatch("listRoles");
  },
  mounted() {
    this.getSegmentAndFormation();
    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    wizard.on("beforeNext", function(/*wizardObj*/) {});

    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  }
};
</script>
