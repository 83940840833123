<template>
  <b-col cols="12">
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-3"
          id="kt_wizard_v3"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">Formación</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div
                class="wizard-step"
                data-wizard-type="step"
                v-if="
                  data.attributes.owner == this.currentUser.id ||
                    this.currentUser.attributes.rol === `socio`
                "
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">Log</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
            <div class="col-xl-12 col-xxl-12">
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <b-row>
                  <b-col cols>
                    <b-card
                      :title="data.attributes.name"
                      :sub-title="
                        `Creado por el usuario: ${data.attributes.owner_name}`
                      "
                      class="card card-custom card-stretch gutter-b"
                      :footer="data.attributes.created"
                      footer-class="text-muted font-weight-bold font-size-lg flex-grow-1"
                    >
                      <b-img
                        :src="data.relationships.segment.attributes.logo"
                        class="rounded-0 m-4"
                        style="width: 5%"
                      />
                      <div
                        v-if="
                          data.attributes.owner == this.currentUser.id ||
                            this.currentUser.attributes.rol === `socio`
                        "
                      >
                        <b class="mt-6">
                          Departamentos que pueden visualizar la Formación: </b
                        ><br />
                        <b-badge
                          variant="secondary"
                          v-for="department in data.relationships.departments"
                          :key="department.id"
                          class="m-2"
                        >
                          {{ department.attributes.name }}
                        </b-badge>
                      </div>

                      <div class="card-body d-flex flex-column">
                        <div class="flex-grow-1 pb-5">
                          <h4>Descripción</h4>
                          <p
                            class="text-dark-50 font-weight-normal font-size-lg mt-1"
                          >
                            {{ data.attributes.description }}
                          </p>
                          <hr />
                          <PDF
                            :url="data.attributes.url"
                            v-if="data.attributes.type === 'application/pdf'"
                          />
                          <TEST
                            v-else-if="data.attributes.type === `test`"
                            @listener="handler"
                            @setAnswers="setAnswers"
                            @listener-resolved="setResolved"
                            :test="data.relationships.test"
                          />

                          <VIDEO :data="data" v-else />
                        </div>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                <WIDGETLOG
                  v-if="
                    data.attributes.owner == this.currentUser.id ||
                      this.currentUser.attributes.rol === `socio`
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { mapGetters } from "vuex";
import PDF from "@/components/formations-module/pdf/pdf";
import TEST from "@/components/formations-module/formations/widget-test";

import WIDGETLOG from "@/components/formations-module/logs/widget-log";

import KTWizard from "@/assets/js/components/wizard";
import KTUtil from "@/assets/js/components/util";
import { SHOW_FORMATION, SHOW_TEST } from "@/services/store/formation.module";
import Swal from "sweetalert2";

import VIDEO from "@/components/formations-module/formations/widget-video";

export default {
  props: ["data"],
  components: { PDF, TEST, WIDGETLOG, VIDEO },
  computed: {
    ...mapGetters([
      "getFormation",
      "currentUser",
      "getLog",
      "getBreadcrumbSegment"
    ])
  },
  data() {
    return {
      isWatching: true,
      showModal: false,
      answered: [],
      resolved: false
    };
  },
  created() {
    window.addEventListener("beforeunload", this.handler2);
  },
  methods: {
    handler() {
      this.showModal = !this.showModal;
    },
    handler2: function handler(event) {
      console.log(event);
      this.$router.push("/");
    },
    setResolved() {
      this.resolved = !this.resolved;
    },
    setAnswers(value) {
      this.answered = value;
    },
    cameout() {
      if (!this.resolved && this.data.attributes.type === `test`) {
        return new Promise((resolve, reject) => {
          Swal.fire({
            title: "<strong>Info</strong>",
            icon: "info",
            html:
              "Estás seguro de que quieres terminar el <strong>test</strong>? ",
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Sí',
            confirmButtonAriaLabel: "Thumbs up, great!",
            cancelButtonText: '<i class="fa fa-thumbs-down"></i> No',
            cancelButtonAriaLabel: "Thumbs down"
          })
            .then(result => {
              if (result.isConfirmed) {
                this.resolved = !this.resolved;
                const payload = {
                  id: this.getLog,
                  action: 2,
                  answered: this.answered
                };
                resolve(payload);
              } else {
                resolve(false);
              }
            })
            .catch(e => {
              reject(e);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          this.resolved = !this.resolved;
          const payload = {
            id: this.getLog,
            action: 2
          };
          if (!this.getLog) {
            reject("error");
          }
          resolve(payload);
        });
      }
    }
  },
  mounted() {
    if (this.data.attributes.type === `test`) {
      this.$store.dispatch(SHOW_TEST, this.data.id);
    } else {
      this.data.attributes.type !== `test`
        ? (this.resolved = !this.resolved)
        : this.resolved;
      this.$store.dispatch(SHOW_FORMATION, this.data.id);
    }
    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1,
      clickableSteps: true
    });

    wizard.on("beforeNext", function(/*wizardObj*/) {});

    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  }
};
</script>
