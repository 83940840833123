<template>
  <b-container>
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab
            title="Solicitudes en Calendario"
            v-if="currentUser.attributes.rol_id != 3"
            :active="example == 'listVacationsCalendar' ? true : false"
          >
            <div><LISTVACATIONCALENDAR :holidays="getHolidays" /></div>
          </b-tab>
          <b-tab
            title="Solicitudes Realizadas"
            v-if="currentUser.attributes.rol_id != 3"
          >
            <div><LISTVACATION :holidays="getHolidays" /></div>
          </b-tab>
          <b-tab
            title="Solicitudes Pendientes"
            v-if="currentUser.attributes.rol_id === 2 && havePermission"
          >
            <div><LISTREQUEST :holidays="getHolidays" /></div>
          </b-tab>
          <b-tab
            title="Lista Empleados"
            v-if="currentUser.attributes.rol_id != 3"
          >
            <div><LISTPROFILE :user="currentUser" :isr="havePermission" /></div>
          </b-tab>
          <b-tab
            title="Mis Vacaciones"
            :active="
              this.currentUser.attributes.rol_id == 3 ||
              example == 'myVacations'
                ? true
                : false
            "
          >
            <div><LISTUSERVACATION :holidays="getHolidays" /></div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

import LISTPROFILE from "@/components/holidays-module/list/list-profile";
import LISTREQUEST from "@/components/holidays-module/list/list-request";
import LISTVACATION from "@/components/holidays-module/list/list-vacation";
import LISTVACATIONCALENDAR from "@/components/holidays-module/list/list-vacation-calendar";

import LISTUSERVACATION from "@/components/holidays-module/list/list-user-vacation";
import { SHOW_PROFILE } from "@/services/store/profile.module.js";
import { LIST_HOLIDAYS } from "@/services/store/festivity.module.js";
import { LIST_VACATIONS } from "@/services/store/holiday.module.js";
export default {
  computed: {
    ...mapGetters([
      "currentUser",
      "getEmployeesAll",
      "getVacation",
      "getHolidays"
    ]),
    havePermission() {
      return this.currentUser.attributes.permission_add === 1 ? true : false;
    }
  },
  components: {
    LISTPROFILE,
    LISTREQUEST,
    LISTVACATION,
    LISTUSERVACATION,
    LISTVACATIONCALENDAR
  },
  data() {
    return {
      example: this.$route.params.example
    };
  },

  beforeMount() {
    this.$store.dispatch(SHOW_PROFILE, this.currentUser.id);
    this.$store.dispatch(LIST_VACATIONS);
    this.$store.dispatch(LIST_HOLIDAYS);
  },
  methods: {}
};
</script>
