<template>
  <div>
    <b-row>
      <b-col
        class="mb-n4 mr-n3"
        v-for="(element, idx) in files"
        :key="idx"
        cols
        md="4"
      >
        <b-card
          bg-variant="white"
          no-body
          class="card-custom card-stretch gutter-b"
        >
          <!-- {{ element }} -->
          <div v-b-tooltip.hover class="text-center" :title="element.name">
            <b-row class="mt-2 ml-2 mr-2">
              <b-col
                lg="10"
                sm="8"
                @click="showAttachment(element)"
                style="cursor: pointer"
                class="pt-2"
              >
                <div>
                  <template v-if="isType(element.type) === 'image'">
                    <b-img
                      style="height: 100px; object-fit: cover"
                      :src="getUrlFile(element)"
                      fluid
                      alt="Responsive image"
                    ></b-img>
                  </template>
                  <template v-else-if="isType(element.type) == 'video'">
                    <b-embed
                      type="video"
                      aspect="16by9"
                      controls
                      poster="poster.png"
                    >
                      <source :src="getUrlFile(element)" type="video/mp4" />
                    </b-embed>
                  </template>
                  <template v-else-if="isType(element.type) === 'application'">
                    <span class="svg-icon svg-icon-sm">
                      <b-img
                        style="height: 90px; object-fit: cover"
                        src="media/svg/files/doc.svg"
                        fluid
                        alt="Center image"
                      ></b-img>
                    </span>
                  </template>
                </div>
              </b-col>
              <b-col align="right" lg="2" sm="4">
                <b-button
                  @click="deleteAttachment(element)"
                  class="btn btn-white btn-hover-light-primary btn-sm btn-icon"
                >
                  <b-icon href="#" icon="x-circle" variant="dark"></b-icon>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-message-attachment"
      :title="fileAttachment.name"
      v-model="handleHide"
      size="xl"
    >
      <WIDGETSHOWATTACHMENT :fileAttachment="fileAttachment" />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleHide = false"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import WIDGETSHOWATTACHMENT from "@/components/chats-module/attachment/widget-show-attachment";
const Swal = require("sweetalert2");

export default {
  props: ["files"],
  components: {
    WIDGETSHOWATTACHMENT
  },
  comments: {
    Swal
  },
  computed: {},
  data() {
    return {
      handleHide: false,
      fileAttachment: {}
    };
  },
  methods: {
    isType(name) {
      var n = name.split("/");
      return n[0];
    },
    subName(name) {
      var n = name.split(".");
      return n[0].substr(0, 13).concat(".", n[1]);
    },
    showAttachment(file) {
      this.fileAttachment.name = file.name;
      this.fileAttachment.type = file.type;
      this.fileAttachment.url = URL.createObjectURL(file);
      this.handleHide = true;
    },
    getUrlFile(file) {
      return URL.createObjectURL(file);
    },
    deleteAttachment(file) {
      var i = this.files.indexOf(file);
      this.files.splice(i, 1);
      Swal.fire("Exito!", "El archivo fue emilinado", "success");
      this.$emit("delete-file", file);
    }
  }
};
</script>
