<template>
  <b-col
    v-if="
      (doctype.attributes.name === 'valoraciones' &&
        this.currentUser.attributes.rol_id === 1) ||
        (doctype.attributes.name === 'valoraciones' &&
          this.currentUser.attributes.rol_id === 2) ||
        (doctype.attributes.name === 'contratos' &&
          this.currentUser.attributes.rol_id === 1) ||
        (doctype.attributes.name === 'circulares' &&
          this.currentUser.attributes.rol_id === 1) ||
        (doctype.attributes.name === 'circulares' &&
          this.currentUser.attributes.rol_id === 2) ||
        (doctype.attributes.name === 'circulares' &&
          this.currentUser.attributes.rol_id === 3) ||
        (doctype.attributes.name === 'nominas' &&
          this.currentUser.attributes.rol_id === 3) ||
        (doctype.attributes.name === 'nominas' &&
          this.currentUser.attributes.rol_id === 2) ||
        (doctype.attributes.name === 'nominas' &&
          this.currentUser.attributes.rol_id === 1) ||
        (doctype.attributes.name === 'circulares' &&
          this.currentUser.attributes.rol_id === 2)
    "
    @click="show"
    style="cursor:pointer;"
    cols
    md="3"
    sm="12"
  >
    <b-card
      class="card-custom card-stretch gutter-b"
      @click="show()"
      style='cursor:pointer;background-position: right; background-size: 15%; background-image: url("media/svg/shapes/folder2.svg");'
    >
      <a
        class="font-weight-normal text-primary text-hover-primary font-size-lg mb-1"
        style="font-size: 16px"
      >
        {{ doctype.attributes.name }}
      </a>
    </b-card>
  </b-col>
</template>
<script>
import { mapGetters } from "vuex";
import { LIST_DOCUMENTS } from "@/services/store/document.module";
import { SELECT_DOCTYPE } from "@/services/store/doctype.module";

export default {
  props: ["doctype"],

  computed: {
    ...mapGetters([
      "currentUser",
      "getEmployeeProfile",
      "getDocuments",
      "currentUser"
    ])
  },
  methods: {
    show() {
      this.$store.dispatch(LIST_DOCUMENTS, {
        doctype: this.doctype.id,
        employee_id: this.getEmployeeProfile.id
      });
      this.$store.dispatch(SELECT_DOCTYPE, { doctype: this.doctype });

      this.$emit("show-doctype", this.doctype);
    }
  }
};
</script>
