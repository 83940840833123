var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isAdmin)?_c('div',{staticClass:"header flex-column header-fixed"},[_c('div',{staticClass:"header-top",staticStyle:{"margin-top":"0px !important"}},[_c('div',{staticClass:"container"},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"primary"}},[_c('b-navbar-brand',{staticClass:"ml-4"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"max-h-50px",attrs:{"alt":"Logo","src":_vm.layoutConfig('self.logo.default')}})])],1),_c('b-navbar-toggle',{staticClass:"ml-auto",attrs:{"target":"nav-collapse"}}),_c('b-collapse',{class:_vm.widthFluid ? "header-tabs nav align-self-end font-size-lg" : "",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('a',{class:this.$route.name === 'dashboard'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"0","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"}},[_vm._v("Inicio")])])],1)]),_c('b-navbar-nav',[_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":{ name: 'formations' }}},[_c('a',{class:this.$route.path === '/formaciones/*' ||
                    this.$route.path === '/folders/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"}},[_vm._v(" Formaciones ")])])],1)]),_c('b-navbar-nav',[(_vm.permissions)?_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":{ name: 'activities' }}},[_c('a',{class:this.$route.path === '/actividades/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"}},[_vm._v(" Actividad ")])])],1):_vm._e()]),(_vm.permissions)?_c('b-navbar-nav',[_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":{ name: 'tasks' }}},[_c('a',{class:this.$route.path === '/tareas/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"}},[_vm._v(" Tareas ")])])],1)]):_c('b-navbar-nav',[_c('li',{staticClass:"nav-item mr-3"},[_c('a',{class:this.$route.path === '/tareas/*'
                    ? 'nav-link py-4 px-6 active'
                    : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"},on:{"click":function($event){return _vm.showTasksUser()}}},[_vm._v(" Mis tareas ")])])]),_c('b-navbar-nav',[(_vm.permissions)?_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":{ name: 'employees' }}},[_c('a',{class:this.$route.path === '/empleados/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"}},[_vm._v(" Empleados ")])])],1):_vm._e()]),_c('b-navbar-nav',[_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":{ name: 'departments' }}},[_c('a',{class:this.$route.path === '/departamentos/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"}},[_vm._v(" Departamentos ")])])],1)]),_c('b-navbar-nav',[_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":{
                  name: 'holidays',
                  params: {
                    example: 'listVacations'
                  }
                }}},[_c('a',{class:this.$route.path === '/vacaciones/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"}},[_vm._v(" "+_vm._s(this.currentUser.attributes.rol_id == 3 ? "Mis Vacaciones" : "Vacaciones")+" ")])])],1)]),_c('b-navbar-nav',[_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":{
                  name: 'chats',
                  params: {
                    chat: _vm.chatId //codificar el id
                  }
                }}},[_c('a',{class:this.$route.path === '/chats/*'
                      ? 'nav-link py-4 px-6 active'
                      : 'nav-link py-4 px-6',attrs:{"data-tab":"1","data-toggle":"tab","role":"tab","aria-selected":"true"}},[_c('span',{staticClass:"symbol symbol-35"},[_vm._v(" Chats "),(_vm.unreadMessages > 0)?_c('small',{staticClass:"symbol-badge bg-primary"},[_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_vm._v(_vm._s(_vm.unreadMessages)+" ")])],1):_vm._e()])])])],1)])],1),_c('div',{attrs:{"id":"topbar"}},[_c('TOPBAR')],1)],1)],1)]),_c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"container mt-6"},[_c('BREADCRUMB')],1)])]):_c('HEADERADMIN')}
var staticRenderFns = []

export { render, staticRenderFns }