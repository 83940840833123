<template>
  <div class="card-body p-0">
    <div
      class="wizard wizard-3"
      id="kt_wizard_v3"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
        <div class="col-xl-12 col-xxl-12">
          <div
            class="pb-5"
            data-wizard-type="step-content"
            data-wizard-state="current"
          >
            <h4 class="mb-4 font-weight-bold text-dark">
              Información de la Valoración
            </h4>
            <b-form>
              <b-form-group
                id="input-group-1"
                label="Titulo del documento"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  type="text"
                  required
                  placeholder="Titulo del documento"
                />
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                  :state="Boolean($v.form.name.$model)"
                >
                  El titulo es requerido
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Descripción de la valoración"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea-no-resize"
                  placeholder="Descripción"
                  rows="3"
                  no-resize
                  v-model="form.description"
                />
              </b-form-group>
              <b-row>
                <b-col cols class="mb-4">
                  <vue-excel-editor
                    v-model="this.jsondata"
                    no-footer
                    :page="10"
                    ref="tableRatting"
                    id="table"
                  >
                    <vue-excel-column
                      field="av"
                      label="Aspecto a valorar"
                      readonly
                      type="string"
                      width="250px"
                    />
                    <vue-excel-column
                      field="rating"
                      label="Valoración (1 al 10)"
                      type="number"
                      width="150px"
                      summary="sum"
                    />
                    <vue-excel-column
                      field="comment"
                      label="Comentarios"
                      type="string"
                      width="250px"
                    />
                  </vue-excel-editor>
                </b-col>
              </b-row>

              <b-progress
                :max="max"
                v-if="load"
                class="mb-4"
                style="margin-bottom: 5px"
              >
                <b-progress-bar
                  :value="this.loading"
                  :label="`${this.loading}%`"
                ></b-progress-bar>
              </b-progress>

              <b-button
                type="button"
                ref="btnSubmit"
                variant="outline-primary"
                @click="onSubmit"
                >Guardar</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  STORE_DOCUMENT,
  UPDATE_DOCUMENT
} from "@/services/store/document.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import aws from "@/utils/config";
import md5 from "md5";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  props: ["document", "action"],
  data() {
    return {
      attachment: null,
      form: {
        id: this.action === `edit` ? this.document.id : "",
        name: this.action === `edit` ? this.document.attributes.name : "",
        description:
          this.action === `edit` ? this.document.attributes.description : "",
        url: this.action === `edit` ? this.document.attributes.url : "",
        type: this.action === `edit` ? this.document.attributes.type : ""
      },
      jsondata: [
        {
          av: "Productividad",
          rating: "",
          comment: ""
        },
        {
          av: "Actitud",
          rating: "",
          commnet: ""
        },
        {
          av: "Aptitud",
          rating: "",
          commnet: ""
        },
        {
          av: "Aprendizaje y evolución",
          rating: "",
          commnet: ""
        },
        {
          av: "Relación con compañeros",
          rating: "",
          commnet: ""
        },
        {
          av: "Puntualidad",
          rating: "",
          commnet: ""
        },
        {
          av: "Vestimenta",
          rating: "",
          commnet: ""
        },
        {
          av: "Capacidad de liderazgo",
          rating: "",
          commnet: ""
        },
        {
          av: "Capacidad de adaptación a cambios",
          rating: "",
          commnet: ""
        }
      ],
      errors: "",
      loading: 0,
      load: false,
      max: 100
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  created() {
    if (this.action === `edit`) {
      this.jsondata = JSON.parse(this.form.url);
    }
  },
  computed: {
    ...mapGetters([
      "getBreadcrumbDoctype",
      "getBreadcrumbPath",
      "getDoctype",
      "getEmployeeProfile"
    ]),
    allowedExtensions() {
      let extensions = "";
      if (this.getBreadcrumbDoctype.name === "assessment") {
        extensions = ".pdf";
      }
      return extensions;
    }
  },
  methods: {
    nameWithLang({ text }) {
      return `${text}`;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.action === `edit`) {
        this.update();
      } else {
        const submitButton = this.$refs["btnSubmit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        this.form.name = this.$v.form.name.$model;
        this.form.type = "xlsx";
        this.form.doctype = this.getDoctype.doctype.id;
        this.form.url = this.jsondata;

        //usuario de perfil
        this.form.employees = [
          {
            value: this.getEmployeeProfile.id,
            text: this.getEmployeeProfile.attributes.name
          }
        ];

        this.form.path = this.getBreadcrumbPath
          ? `${this.getBreadcrumbPath}/${this.form.name}`
          : "";

        this.$store
          .dispatch(STORE_DOCUMENT, this.form)
          .then(response => {
            if (response.status == 200 || response.status == 201) {
              this.$refs["btnSubmit"].classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
              Swal.fire("Ok!", "Se creo la valoracion exitosamente", "success");
              this.$emit("store-assessment", "modal-assessment");
            }
          })
          .catch(e => {
            Swal.fire("", "Por favor! intente de nuevo", "error");
            console.log(e);
          });
      }
    },
    async s3upload() {
      const files = document.getElementById("attachment").files;
      var file = files[0];
      var fileName = md5(`${files[0].name}-${Date.now()}`);

      const path = `employees/${this.getBreadcrumbPath}/${fileName}`;

      const params = {
        Bucket: `${process.env.VUE_APP_BUCKETNAME}`,
        Key: `${path}`,
        Body: file,
        ACL: "public-read"
      };

      this.form.name = this.$v.form.name.$model;
      this.form.type = file.type;
      this.form.doctype = this.getDoctype.doctype.id;
      //usuario de perfil
      this.form.employees = [
        {
          value: this.getEmployeeProfile.id,
          text: this.getEmployeeProfile.attributes.name
        }
      ];

      this.form.path = this.getBreadcrumbPath
        ? `${this.getBreadcrumbPath}/${this.form.name}`
        : "";
      this.load = true;

      const ACTION = this.form.document ? STORE_DOCUMENT : STORE_DOCUMENT;

      return await aws
        .s3()
        .upload(params)
        .on("httpUploadProgress", evt => {
          var uploaded = Math.round((evt.loaded / evt.total) * 100);
          this.loading = uploaded;
        })
        .send((err, data) => {
          if (err) {
            Swal.fire("", "Por favor! intente de nuevo", "error");
            console.log(err, err.stack);
            return;
          }
          if (data.Location) {
            this.form.url = data.Location;
            this.$store
              .dispatch(ACTION, this.form)
              .then(response => {
                if (response.status == 200 || response.status == 201) {
                  this.$refs["btnSubmit"].classList.remove(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                  );
                  Swal.fire(
                    "Ok!",
                    "Se creo la valoracion exitosamente",
                    "success"
                  );
                  this.$emit("store-assessment", "modal-assessment");
                }
              })
              .catch(e => {
                Swal.fire("", "Por favor! intente de nuevo", "error");
                console.log(e);
              });
          }
        });
    },
    async update() {
      const submitButton = this.$refs["btnSubmit"];
      this.form.doctype = this.getBreadcrumbDoctype.id ?? "";

      this.form.url = this.jsondata;
      await this.$store
        .dispatch(UPDATE_DOCUMENT, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire(
              "Ok!",
              "Se modifico la valoracion exitosamente",
              "success"
            );
            this.$emit(
              "store-assessment-update",
              `modal-assessment-${this.form.id}`
            );
          }
        })
        .catch(e => {
          console.log(e.response.data);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    }
  },
  mounted() {}
};
</script>
