<template>
  <b-container>
    <WIDGETMODALCREATE
      v-if="
        this.currentUser.attributes.rol === 'socio' ||
          this.currentUser.attributes.rol === 'lider'
      "
    />
    <b-row
      v-if="
        this.currentUser.attributes.rol === 'socio' ||
          this.currentUser.attributes.rol === 'lider'
      "
    >
      <WIDGETFOLDER
        :folder="element"
        v-for="(element, idx) in getChildren"
        :key="idx"
      />
    </b-row>

    <b-row>
      <b-col cols="3" v-for="(element, idx) in getFormations" :key="idx">
        <WIDGETFORMATION
          :formation="element"
          v-if="
            (element.relationships.folder == null &&
              element.attributes.active == true) ||
              element.attributes.owner == currentUser.id
          "
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import WIDGETFOLDER from "@/components/formations-module/folders/widget-folder";
import WIDGETFORMATION from "@/components/formations-module/formations/widget-formation";
import WIDGETMODALCREATE from "@/components/formations-module/widget-modal-create";

export default {
  data() {
    return {};
  },
  components: {
    WIDGETFOLDER,
    WIDGETFORMATION,
    WIDGETMODALCREATE
  },
  computed: {
    ...mapGetters(["getContentFolder", "currentUser"]),
    getChildren() {
      if (typeof this.getContentFolder.relationships !== "undefined") {
        if (
          typeof this.getContentFolder.relationships.children !== "undefined"
        ) {
          return this.getContentFolder.relationships.children;
        } else {
          return [];
        }
      }
      return [];
    },
    getFormations() {
      if (typeof this.getContentFolder.relationships !== "undefined") {
        if (
          typeof this.getContentFolder.relationships.formations !== "undefined"
        ) {
          return this.getContentFolder.relationships.formations;
        } else {
          return [];
        }
      }
      return [];
    }
  },
  methods: {},
  mounted() {}
};
</script>
