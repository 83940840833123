import AWS from "aws-sdk";

const aws = {
  s3() {
    return new AWS.S3({
      accessKeyId: `${process.env.VUE_APP_ACCESSKEYID}`,
      secretAccessKey: `${process.env.VUE_APP_SECRETACCESSKEY}`,
      region: `${process.env.VUE_APP_REGION}`
    });
  }
};

export default aws;
