<template>
  <div class="section">
    <v-sheet height="64">
      <b-row>
        <b-col cols md="5">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoy
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </b-col>
        <b-col cols md="6" v-if="$refs.calendar">
          <h4>
            {{ $refs.calendar.title }}
          </h4>
        </b-col>
      </b-row>
    </v-sheet>
    <v-sheet height="650">
      <v-calendar
        locale="es"
        ref="calendar"
        v-model="focus"
        :now="today"
        :events="items"
        :event-more="false"
        :interval-height="100"
        type="month"
        @click:event="showDetail"
        :short-weekdays="false"
        :weekdays="weekday"
      >
        <!-- :interval-height="100" Aumenta en tamaño del dia.. -->

        <template v-slot:event="{ event }" id="eventTemplate">
          <div
            :style="{
              'background-color': event.color,
              color: 'white'
            }"
            class="fill-height pl-2"
          >
            <p>
              <strong>{{ event.name }}</strong>
            </p>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>
<style>
.v-calendar-weekly__week {
  height: 400px;
  overflow: auto;
  overflow-x: hidden;
}
/**
 Fuente: https://ed.team/blog/personaliza-el-scroll-de-tu-web-solo-con-css
 */
.v-calendar-weekly__week::-webkit-scrollbar {
  width: 8px; /* Tamaño del scroll en vertical */
  height: 8px; /* Tamaño del scroll en horizontal */
}
.v-calendar-weekly__week::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.v-calendar-weekly__week::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
/* Cambiamos el fondo cuando esté en active */
.v-calendar-weekly__week::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
.v-calendar-weekly__week::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}
/* Cambiamos el fondo cuando esté en active o hover */
.v-calendar-weekly__week::-webkit-scrollbar-track:hover,
.v-calendar-weekly__week::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
/*******************************************************************/
</style>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
const Swal = require("sweetalert2");

import { LIST_VACATIONS } from "@/services/store/holiday.module.js";
export default {
  props: {
    holidays: Array
  },
  data() {
    return {
      focus: "",
      today: "2021-03-29",
      events: [],
      start: null,
      end: null,
      colors: [
        "#006600",
        "#003399",
        "#666699",
        "#996633",
        "#660066",
        "#cccc99",
        "#663300"
      ],
      name: null,
      weekday: [1, 2, 3, 4, 5]
    };
  },
  computed: {
    ...mapGetters(["currentUser", "getVacations"]),
    items() {
      let list = [];
      this.getVacations.forEach(v => {
        if (v.relationships.status.id === 9) {
          let start = new moment(v.attributes.date_start).format("YYYY-MM-DD");
          let over = new moment(v.attributes.date_over).format("YYYY-MM-DD");
          list.push({
            name: `${v.relationships.user.attributes.name}`,
            start: start,
            end: over,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            total: v.attributes.days_vacations,
            replacement: v.relationships.replacement.attributes.name
          });
        }
      });
      return list;
    }
  },
  methods: {
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    showDetail(data) {
      Swal.fire({
        title: `<h2> Nombre: ${data.event.name}</h2>`,
        html: `<h4>Fecha de vacacion</h4> <p><strong>Inicia el:</strong> ${new moment(
          data.event.start
        ).format(
          "DD / MMMM / YYYY"
        )}</p> <p><strong>Termina el: </strong>${new moment(
          data.event.end
        ).format("DD / MMMM / YYYY")}</p> 
        <p><strong>Remplazo:</strong> ${data.event.replacement}</p>
        <p><strong>Cantidad dias: </strong> ${data.event.total}</p> `,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Aceptar"
      });
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_VACATIONS);
  }
};
</script>
