<template>
  <b-row v-if="this.file">
    <b-col align="center" v-if="this.file">
      <PDF :url="file.url" v-if="file.type === `application/pdf`" />
      <img
        :src="file.url"
        :alt="file.name"
        width="80%"
        height="400px"
        v-else-if="
          file.type === `image/png` ||
            file.type === `image/jpg` ||
            file.type === `image/jpeg`
        "
      />
      <div v-else-if="file.type === `video/mp4`">
        <div>
          <video
            ref="videoPlayerAttachment"
            preload="auto"
            class="video-js"
          ></video>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<style lang="scss">
@import "@/assets/css/video-js";
</style>
<script>
import PDF from "@/components/formations-module/pdf/pdf";
import videojs from "video.js";
export default {
  props: ["fileAttachment"],
  components: {
    PDF
  },
  data() {
    return {
      file: this.fileAttachment,
      player: null,
      options: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: "",
            type: ""
          }
        ]
      }
    };
  },
  mounted() {
    if (this.file.type === `video/mp4`) {
      this.options.sources[0].src = this.file.url;
      this.options.sources[0].type = this.file.type;
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {}
      );
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>
