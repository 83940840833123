<template>
  <div>
    <b-row>
      <b-col lg="4">
        <b-form-group
          v-if="type != 'direct'"
          id="fieldset-1"
          label="Nombre Chat"
          label-for="input-1"
        >
          <b-form-input
            :class="errors.response ? 'border border-danger' : ''"
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            type="text"
            placeholder="Ingrese el nombre"
            required
          >
          </b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback">
            El nombre es requerido
          </b-form-invalid-feedback>
          <p v-if="errors.response" class="text-danger mt-1">
            <span v-for="(e, i) in errors.response.data.errors.name" :key="i">
              {{ e }}</span
            >
          </p>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group
          v-if="type != 'direct'"
          id="fieldset-1"
          label="Tipo de canal"
          label-for="input-1"
        >
          <multiselect
            @input="typeChatSelect"
            v-model="type_select"
            :options="types"
            :group-select="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione uno tipo"
            label="label"
            track-by="label"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group
          v-if="type != 'direct'"
          id="fieldset-1"
          label="Tipo de selección"
          label-for="input-1"
        >
          <multiselect
            @input="selectionEmployees"
            v-model="selection"
            :options="selection_employees"
            :group-select="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione uno tipo"
            label="label"
            track-by="label"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group id="fieldset-1" label="Empleados" label-for="input-1">
          <multiselect
            @input="nameChatDirect"
            v-model="employees"
            :options="employees_all"
            :multiple="type != 'direct' ? true : false"
            :group-select="true"
            :close-on-select="type != 'direct' ? false : true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione los empleados"
            label="name"
            track-by="name"
          >
            <span slot="noOptions">Lista vacia, No existe mas usuarios.</span>
            <span slot="noResult"
              >Oops! No se encontraron elementos. Considere cambiar la consulta
              de búsqueda.</span
            >
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <div class="text-right mt-3">
      <b-button ref="btnSubmit" @click="save" variant="primary" size="sm">
        Añadir
      </b-button>
      <b-button
        class="ml-3"
        variant="secondary"
        size="sm"
        @click="$emit('close-modal-add', null)"
      >
        Cerrar
      </b-button>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
import {
  LIST_EMPLOYEES_ALL,
  LIST_EMPLOYEES_CHAT
} from "@/services/store/employee.module";
import {
  STORE_CHAT,
  UPDATE_CHAT,
  LIST_CHATS
} from "@/services/store/chat.module";

import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  props: ["chat", "action", "type"],
  mixins: [validationMixin],

  components: {
    Multiselect
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
    this.$store.dispatch(LIST_EMPLOYEES_CHAT);
    this.$store.dispatch(LIST_CHATS);
  },
  data() {
    return {
      selection_employees: [
        { id: 5, label: "Personalizado" },
        { id: 4, label: "Todos" },
        { id: 1, label: "Socios" },
        { id: 2, label: "Lideres" },
        { id: 3, label: "Empleados" }
      ],
      selection: { id: 5, label: "Personalizado" },
      name: "",
      employees: this.action === `edit` ? this.chat.employees_select : [],
      type_select:
        this.action != `edit` && this.type === "direct"
          ? { name: "direct", label: "Directo" }
          : {
              name: this.type,
              label: this.type === `private` ? "Privado" : "publico"
            },

      types:
        this.type === "direct"
          ? [{ name: "direct", label: "Directo" }]
          : [
              { name: "private", label: "Privado" },
              { name: "public", label: "Publico" }
            ],
      form: {
        id: this.action === `edit` ? this.chat.id : "",
        name: this.action === `edit` ? this.chat.attributes.name : "",
        employees:
          this.action === `edit` ? this.chat.relationships.employees : [],
        type:
          this.action === `edit`
            ? this.chat.attributes.type
            : this.type != "direct"
            ? {}
            : this.type
      },
      errors: {}
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters(["getEmployeesAll", "getEmployeesChat", "getListChats"]),
    employees_all() {
      if (this.type_select == "private") {
        return _.map(this.getEmployeesChat, d => {
          return { id: d.id, name: d.attributes.name };
        });
      } else {
        return _.map(this.getEmployeesAll, d => {
          return { id: d.id, name: d.attributes.name };
        });
      }
    }
  },
  methods: {
    selectionEmployees() {
      this.employees = [];
      if (this.type_select.name !== "direct") {
        this.getEmployeesAll.forEach(e => {
          if (this.selection.id === 4) {
            this.employees.push({ id: e.id, name: e.attributes.name });
          } else if (this.selection.id === e.attributes.role_id) {
            this.employees.push({ id: e.id, name: e.attributes.name });
          }
        });
      }
    },
    selct() {
      let obj = { name: "", label: "" };
      if (this.chat.attributes.type != "direct") {
        obj.name = this.chat.attributes.type;
        if (this.chat.attributes.type === `public`) {
          obj.label = "Publico";
        } else {
          obj.label = "Privado";
        }
      } else {
        obj.name = "direct";
        obj.label = "Directo";
      }
      return obj;
    },
    nameChatDirect() {
      if (this.type === "direct") {
        this.form.name = this.employees.name;
      }
      this.form.employees =
        this.type != "direct"
          ? _.map(this.employees, d => {
              return d.id;
            })
          : [this.employees.id];
    },
    typeChatSelect() {
      this.form.type = this.type_select.name;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]; //nueva forma de scar de un objero solo lo que quieres
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const ACTION_DISPACH = this.action != "edit" ? STORE_CHAT : UPDATE_CHAT;
      var message =
        this.action === "edit"
          ? "El chat se actualizo correctamente"
          : "El chat se creo correctamente";
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.form.name = this.$v.form.name.$model;
      this.form.type = this.type_select.name;
      this.form.employees =
        this.type != "direct"
          ? _.map(this.employees, d => {
              return d.id;
            })
          : [this.employees.id];
      let user = this.employees.id;
      let have = _.find(this.getListChats, function(chat) {
        if (chat.attributes.type == "direct") {
          return _.find(chat.relationships.employees, function(e) {
            if (e.id == user) {
              return e;
            }
          });
        }
      });
      if (!have) {
        this.$store
          .dispatch(ACTION_DISPACH, this.form)
          .then(response => {
            if (response.status == 200 || response.status == 201) {
              this.$socket.emit("ADD_CHAT", response.data.data);
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
              Swal.fire("Ok!", message, "success");
              this.$emit("close-modal-add", response.data.data);
            }
          })
          .catch(e => {
            this.errors = e;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Error!!", "Por favor! intente de nuevo", "error");
            console.log(e);
          });
      } else {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        Swal.fire(
          "Alerta!",
          "Tienes un converciaion iniciada con el usuario",
          "warning"
        );

        this.$emit("close-modal-add", null);
      }
    }
  }
};
</script>
