<template>
  <div class="card-body p-0">
    <div
      class="wizard wizard-3"
      id="kt_wizard_v3"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
        <div class="col-xl-12 col-xxl-12">
          <div data-wizard-type="step-content" data-wizard-state="current">
            <h3 class="mb-4 font-weight-bold text-dark">
              Formación: {{ this.getCurrentlyFormationSelected.name }}
            </h3>
            <hr />
            <b-form>
              <b-form-group
                id="input-group-1"
                label="Titulo (*)"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  type="text"
                  required
                  placeholder="Titulo del Doc"
                />
                <b-form-invalid-feedback :state="Boolean($v.form.name.$model)">
                  Se debe indicar el titulo del archivo
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Descripción"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea-no-resize"
                  placeholder="Descripción"
                  rows="3"
                  no-resize
                  v-model="form.description"
                />
              </b-form-group>
              <b-form-group
                label="Departamentos que pueden visualizar el archivo (*)"
                label-for="input-3"
              >
                <multiselect
                  v-model="form.departments"
                  :options="departments"
                  :multiple="true"
                  :group-select="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Seleccione un(os) departamento(s)"
                  label="text"
                  track-by="text"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} seleccionados</span
                    ></template
                  >
                </multiselect>
                <b-form-invalid-feedback :state="Boolean(form.departments)">
                  Se debe seleccionar al menos un departamento
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="Seleccione que tipo de usuario puede ver la formación"
                id="input-group-4"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  v-model="form.roles"
                  id="checkboxes-4"
                  :aria-describedby="ariaDescribedby"
                >
                  <b-form-checkbox
                    v-for="rol in roles"
                    :key="rol.value"
                    :value="rol.value"
                    >{{ rol.text }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
                <p class="mt-2" style="color: red">
                  Por defecto, todos los usuarios pueden ver la formación
                </p>
              </b-form-group>

              <b-form-group
                label="Archivo (*)"
                label-for="input-3"
                v-if="this.action != `edit`"
              >
                <b-form-file
                  id="attachment"
                  v-model="attachment"
                  :state="Boolean(attachment)"
                  :accept="this.extensions"
                  placeholder="Subir un archivo"
                  drop-placeholder="Arrastrar archivo aquí..."
                  :disabled="this.extensions !== `` ? false : true"
                />

                <b-form-invalid-feedback :state="Boolean(attachment > 0)">
                  Se debe adjuntar un archivo
                </b-form-invalid-feedback>
              </b-form-group>

              <b-progress
                :max="max"
                v-if="load"
                class="mb-4"
                style="margin-bottom: 5px"
              >
                <b-progress-bar
                  :value="this.loading"
                  :label="`${this.loading}%`"
                ></b-progress-bar>
              </b-progress>

              <b-button
                type="button"
                ref="btnSubmit"
                variant="outline-primary"
                @click="onSubmit"
                >Guardar</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import aws from "@/utils/config";
import Swal from "sweetalert2";
var _ = require("lodash");

export default {
  mixins: [validationMixin],
  props: {
    segmentID: Number,
    action: String,
    formation: Object
  },
  components: {
    Multiselect
  },
  data() {
    return {
      attachment: null,
      selectSegment: false,
      form: {
        id: this.action === `edit` ? this.formation.id : "",
        segment: {},
        folder: {},
        roles:
          this.action === `edit`
            ? this.formation.attributes.roles_have_permission_to_view.split(",")
            : [1, 2, 3],
        name: this.action === `edit` ? this.formation.attributes.name : "",
        url: "",
        description:
          this.action === `edit` ? this.formation.attributes.description : "",
        type: "",
        departments:
          this.action === `edit`
            ? _.map(this.formation.relationships.departments, d => {
                return { value: d.id, text: d.attributes.name };
              })
            : this.departments
      },
      errors: "",
      loading: 0,
      load: false,
      max: 100,
      extensions: "",
      typeExcluded: ["test"],
      codeStatus: [200, 201],
      modalName:
        this.segmentID == 1
          ? "modal-formation-doc"
          : this.segmentID == 2
          ? "modal-formation-video"
          : "modal-test"
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      segment: {
        required
      }
    }
  },
  computed: {
    ...mapGetters([
      "getDepartments",
      "getFolders",
      "getSegments",
      "getCurrentlyFormationSelected",
      "getRoles"
    ]),
    departments() {
      let arrayDepartments = [
        {
          value: 0,
          text: "Todos"
        }
      ];
      const listDepartments = this.getDepartments;
      for (let i = 0; i < listDepartments.length; i++) {
        arrayDepartments.push({
          value: listDepartments[i].id,
          text: listDepartments[i].attributes.name
        });
      }
      return arrayDepartments;
    },
    segments() {
      let arr = [];
      if (this.getSegments.length > 0) {
        for (let i = 0; i < this.getSegments.length; i++) {
          arr.push(this.getSegments[i]);
        }
      }
      return arr;
    },
    roles() {
      let arr = [];
      if (this.getRoles.length > 0) {
        for (let i = 0; i < this.getRoles.length; i++) {
          if (this.getRoles[i].attributes.visible) {
            arr.push({
              text: this.getRoles[i].attributes.name,
              value: this.getRoles[i].id
            });
          }
        }
      }
      return arr;
    }
  },
  methods: {
    getSegmentAndFormation() {
      const foundSegment = this.segments.find(s => s.id == this.segmentID);
      this.form.segment = {
        id: foundSegment.id,
        extensions: foundSegment.attributes.extensions,
        mime: foundSegment.attributes.mime
      };
      this.extensions = foundSegment.attributes.extensions;
      this.form.folder.value = this.getCurrentlyFormationSelected.id;
    },
    allowedExtensions(segment) {
      this.selectSegment = true;
      this.extensions = segment.attributes.extensions;
    },
    getName({ text }) {
      return `${text}`;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        if (this.$v.form.segment.$anyError) {
          this.info.segment = true;
        }
        return;
      }
      // Verifica si existe al menos un valor con 0 (Todos los departamentos)
      if (_.some(this.form.departments, ["value", 0])) {
        this.form.departments = _.filter(this.departments, d => {
          return d.value != 0;
        });
      }

      if (this.form.roles.length == 0) {
        this.form.roles = [1, 2, 3];
      }

      if (this.action === `edit`) {
        this.update();
      } else {
        if (!this.form.folder) {
          return;
        }
        const files = document.getElementById("attachment").files;
        if (!files[0]) {
          return;
        } else {
          const allowedTypeMime = this.form.segment.mime.replace(/ /g, "");
          const splitAllowedTypeMime = allowedTypeMime.split(",");
          if (splitAllowedTypeMime.indexOf(files[0].type) == -1) {
            Swal.fire(
              "Info",
              `Debe seleccionar un archivo de alguno de estos formatos (${this.form.segment.extensions})`,
              "info"
            );
            return;
          }
        }
        if (this.form.departments == 0) {
          return;
        }

        await this.s3upload();
      }
    },
    async s3upload() {
      const files = document.getElementById("attachment").files;
      var file = files[0];
      const path = `employees/formations/${this.form.folder.text}/${file.name}`;
      const params = {
        Bucket: `${process.env.VUE_APP_BUCKETNAME}`,
        Key: `${path}`,
        Body: file,
        ACL: "public-read"
      };

      this.form.name = this.$v.form.name.$model;
      this.form.type = file.type;
      this.form.path = `${this.form.folder.text}/${this.form.name}`;

      this.load = true;

      return await aws
        .s3()
        .upload(params)
        .on("httpUploadProgress", evt => {
          var uploaded = Math.round((evt.loaded / evt.total) * 100);
          this.loading = uploaded;
        })
        .send((err, data) => {
          if (err) {
            Swal.fire("", "Por favor! intente de nuevo", "error");
            console.error(err);
            return;
          }
          if (data.Location) {
            this.form.url = data.Location;
            this.$store
              .dispatch("storeFormation", this.form)
              .then(response => {
                if (this.codeStatus.indexOf(response.status) != -1) {
                  this.$refs["btnSubmit"].classList.remove(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                  );
                  Swal.fire(
                    "Ok!",
                    "Se agrego a la formación exitosamente",
                    "success"
                  );
                  this.$emit("store-formation", this.modalName);
                }
              })
              .catch(e => {
                Swal.fire("", "Por favor! intente de nuevo", "error");
                console.error(e);
              });
          }
        });
    },
    async update() {
      this.form.name = this.$v.form.name.$model;
      const submitButton = this.$refs["btnSubmit"];
      await this.$store
        .dispatch("updateFormation", this.form)
        .then(response => {
          if (this.codeStatus.indexOf(response.status) != -1) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "Se modifico exitosamente", "success");

            this.$store.dispatch("listFormations", {
              folder_id: response.data.data.attributes.folder_id
            });
            this.$emit("update-formation", "modal-formation");
          }
        })
        .catch(e => {
          console.error(e);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    }
  },
  beforeCreate() {
    this.$store.dispatch("listRoles");
  },
  mounted() {
    this.getSegmentAndFormation();
  }
};
</script>
