var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:("message-children-" + (_vm.message.id)),class:("message-children-" + (_vm.message.id)),staticStyle:{"position":"relative","overflow-y":"auto"},style:(_vm.message.relationships.children.length > 1 ? 'height: 200px;' : ''),attrs:{"bg-variant":"danger"}},_vm._l((_vm.message.relationships.children),function(msg,index){return _c('b-alert',{key:index,attrs:{"show":"","variant":msg.attributes.parent_id ? 'secondary' : 'white'}},[_c('b-row',[_c('b-col',[_c('div',[_c('h5',{staticClass:"float-left pt-2"},[_vm._v(" "+_vm._s(msg.relationships.createdBy.name)+" ")]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'info' }),expression:"{ variant: 'info' }",modifiers:{"hover":true}}],staticClass:"float-left pl-2 pt-2",attrs:{"title":((_vm.moment(msg.attributes.created_at).format(
                'dddd - DD MMMM YYYY'
              )) + " a las " + (_vm.moment(msg.attributes.created_at).format(
                'h:mm:ss a'
              )) + " ")}},[_c('strong',[_vm._v(_vm._s(_vm.moment(msg.attributes.created_at) .startOf("minutes") .fromNow()))])])])]),_c('b-col',{class:!msg.attributes.state ||
          (msg.attributes.message == '' &&
            msg.relationships.attachments.length == 0)
            ? 'text-dark'
            : '',attrs:{"md":"12"}},[(msg.attributes.type === 'text')?_c('div',[_vm._v(" "+_vm._s(msg.attributes.state ? msg.attributes.message == "" && msg.relationships.attachments.length == 0 ? "Archivos Eliminado" : msg.attributes.message : "Mensaje eliminado")+" ")]):_vm._e(),(msg.attributes.state)?[_c('WIDGETLISTATTACHMENT',{attrs:{"msg":msg}}),_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[(msg.attributes.created_by === _vm.currentUser.id)?_c('b-badge',{staticClass:"mt-2",staticStyle:{"cursor":"pointer"},attrs:{"pill":"","variant":"outline-primary"},on:{"click":function($event){return _vm.deleteMessage(msg)}}},[_c('strong',[_vm._v("Eliminar")])]):_vm._e()],1)]:_vm._e()],2)],1),_c('b-collapse',{staticClass:"mt-2",attrs:{"accordion":"my-accordion1","role":"tabpanel","id":("accordion-edit-" + (_vm.message.id)),"close":""}},[_c('FORMADDMESSAGE',{attrs:{"msg":msg,"action":'edit'}})],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }