<template>
  <div>
    <b-dropdown
      size="md"
      variant="link"
      toggle-class="btn btn-clean btn-hover-light-primary btn-xs btn-icon"
      no-caret
      right
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-ver"></i>
      </template>
      <div class="navi navi-hover">
        <b-dropdown-text tag="div" class="navi-item">
          <a
            class="navi-link"
            style="cursor:pointer"
            @click="active"
            v-if="data.attributes.type === `test`"
          >
            <span class="navi-icon">
              <b-icon icon="stopwatch-fill" variant="primary"></b-icon>
            </span>
            <span class="navi-text">
              <b v-if="data.attributes.active">activa</b>
              <b v-else>inactiva</b>
            </span>
          </a>

          <div class="navi-link" style="cursor:pointer" @click="showModal">
            <span class="navi-icon">
              <b-icon icon="pencil-fill" variant="primary"></b-icon>
            </span>
            <span class="navi-text">
              Editar
            </span>
          </div>
          <div class="navi-link" @click="deleteF">
            <span class="navi-icon">
              <b-icon icon="dash-circle" variant="danger"></b-icon>
            </span>
            <span class="navi-text">Eliminar</span>
          </div>
        </b-dropdown-text>
      </div>
    </b-dropdown>
    <b-modal ref="modal-folder" hide-footer :title="`${data.attributes.name}`">
      <div class="d-block text-center">
        <FOLDERFORM
          @update-folder="hideModal"
          :folder="data"
          :action="`edit`"
          v-if="data.type === `folders`"
        />
      </div>
    </b-modal>
    <b-modal
      ref="modal-formation"
      hide-footer
      :title="`${data.attributes.name}`"
    >
      <FORMATIONFORM
        @update-formation="hideModal"
        :formation="data"
        :action="`edit`"
        v-if="data.type === `formations`"
        :segmentID="data.attributes.segment_id"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FOLDERFORM from "@/components/formations-module/forms/folder";
import FORMATIONFORM from "@/components/formations-module/forms/formation";
import Swal from "sweetalert2";

export default {
  props: ["data"],
  components: {
    FOLDERFORM,
    FORMATIONFORM
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    hideModal(value) {
      this.$refs[value].hide();
    },
    showModal() {
      if (this.data.type === `folders`) {
        this.$refs[`modal-folder`].show();
      } else {
        this.$refs[`modal-formation`].show();
      }
    },
    deleteF() {
      var text;
      var action;
      var msg;
      if (this.data.type === `folders`) {
        text = "Quieres eliminar la formación?";
        action = "deleteFolder";
        msg = "La formación fue eliminada.";
      } else if (this.data.type === `formations`) {
        text = "Quieres eliminar el archivo? ";
        action = "deleteFormation";
        msg = "El archivo fue eliminado.";
      }
      Swal.fire({
        title: "Estas seguro?",
        text: `${text}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(action, this.data).then(response => {
            if (response.status == 200) {
              if (this.data.type === `folders`) {
                this.$emit("delete-folder");
              }
              Swal.fire("Eliminada!", msg, "success");
            }
          });
        }
      });
    },
    active() {
      this.$emit("listener-active");
    }
  },
  mounted() {}
};
</script>
